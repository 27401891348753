import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#4CAF50",
      },
      dark: {
        primary: "#4CAF50",
      },
    },
  },
  lang: {
    locales: {
      en: {
        dataTable: {
          sortBy: '並べ替え',
        },
      },
    },
    current: 'en',
  },
});
