<template>
  <div :class="{ 'contentsArea': !isSmartPhone }" :style="{'margin-bottom': isSmartPhone ? '115px' : undefined}">
    <v-speed-dial v-if="isSmartPhone" bottom left fixed class="mb-3" style="z-index: 5;">
      <template v-slot:activator>
        <v-btn color="primary" dark @click="$router.push({ name: 'DeliveryList' })">
          <v-icon left>mdi-arrow-left-circle</v-icon>前へ戻る
        </v-btn>
      </template>
    </v-speed-dial>

    <v-card-actions style="max-height:50px;">
      <div class="text-right ma-4 row">
        <v-btn color="primary" text @click="$router.push({ name: 'DeliveryList' })">
            <v-icon left>mdi-arrow-left-circle</v-icon>前へ戻る
        </v-btn>
      </div>
    </v-card-actions>
    
    <v-card outlined class="ma-4 mt-0">

      <v-card-actions style="max-height:50px;">
        <div class="text-right mt-4 ms-2">
          <p class="text-h6">既読状態確認画面</p>
        </div>
      </v-card-actions>
      
      <v-divider></v-divider>
      
      <div class="px-4 py-4">
        
        <p class="mb-3" style="font-size: 18px;">{{ delivery.delivery_name }}</p>
        <p class="mb-1">配信日時：{{ formatToYYYYMMDDHHmmss(delivery.delivered_at) }}</p>
        <p class="mb-1">配信者：{{ delivery.last_name }} {{ delivery.first_name }}</p>
        <p>既読数：{{ this.readStudentsCount }} / {{ this.readParentsList.length }}</p>

        <div class="message-preview" :style="{'z-index': isSmartPhone ? 5 : 11}">
          <v-menu v-model="preview" bottom offset-y nudge-bottom="5px" opacity="0" :close-on-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" min-width="320" v-bind="attrs" v-on="on" @click="preview = !preview">
                <v-icon left> {{ preview ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                配信内容を表示する
              </v-btn>
            </template>
            <div>
              <MessagePreview
                :title="deliveryDetail.deliveryName"
                :messages="deliveryDetail.messages"
                :positionInfo="emojisPositionInfo"
                :preview="preview"
                :url="this.deliveryDetail.messages.map(message => message.previewImageUrl)"
              />
            </div>
          </v-menu>
        </div>

        <v-form @submit.prevent ref="form" class="mt-8">
          <v-row>
            <v-col :cols="isSmartPhone ? 6 : 2">
              <v-autocomplete 
                :items="classroomAll"
                :item-text="item => item.grade_name"
                item-value="grade_name"
                v-model="selectedGrade"
                @change="onGradeChange"
                clearable
                :inputmode="isSmartPhone ? 'none' : undefined"
                label="学年"
              >
                <template v-slot:no-data>
                  <div class="px-4">データがありません</div>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- クラス -->
            <v-col :cols="isSmartPhone ? 6 : 2">
              <v-autocomplete 
                :items="filteredClass"
                :item-text="item => item.class_name"
                item-value="class_name"
                v-model="selectedClass"
                :disabled="!selectedGrade"
                clearable
                :inputmode="isSmartPhone ? 'none' : undefined"
                label="クラス"
              >
                <template v-slot:no-data>
                  <div class="px-4">データがありません</div>
                </template>
              </v-autocomplete>
            </v-col>

            <!-- 氏名（姓） -->
            <v-col :cols="isSmartPhone ? 6 : 2">
              <v-text-field v-model="inputLastName" label="氏名（姓）">
              </v-text-field>
            </v-col>

            <!-- 氏名（名） -->
            <v-col :cols="isSmartPhone ? 6 : 2">
              <v-text-field v-model="inputFirstName" label="氏名（名）">
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <div :class="{'smartphone-data-table': isSmartPhone}">
          <v-data-table class="reply-message-table mt-6"
              :style="{width: isSmartPhone ? '100%' : '80%'}"
              :loading="loading"
              :loading-text="loadingText"
              :no-data-text="noDataText"
              :no-results-text="noResultText"
              :headers="headers"
              :items="readParentsList"
              single-select
              :items-per-page="10"
              :footer-props="{ 'items-per-page-options': [10, 20, 50, 100, -1], 'items-per-page-text': '行/ページ:' }"
              :page.sync="currentPage"
            >
              <template v-slot:[`item.student_last_name`]="{}">
              </template>
              <template v-slot:[`item.student_first_name`]="{ item }">
                <div>{{ item.student_last_name + ' ' + item.student_first_name }}</div>
              </template>
              <template v-slot:[`item.parent_first_name`]="{ item }">
                <div v-for="(lastName, index) in item.parent_last_name" :key="index">
                  <div v-if="lastName !== undefined && item.read_flag[index] == 1">
                    {{ lastName + ' ' + item.parent_first_name[index] }}
                  </div>
                </div>
                <div v-if="item.parent_last_name.every(name => !name)" class="grey--text">保護者未登録</div>
                <div v-else-if="item.read_flag.every(flag => flag === 0)">ー</div>
              </template>
              <template v-slot:[`item.read_flag`]="{ item }">
                <span v-if="item.read_flag.includes(1)"><v-chip :small="isSmartPhone" color="blue" text-color="white">既読</v-chip></span>
                <span v-else><v-chip :small="isSmartPhone">未読</v-chip></span>
              </template>
              <template v-slot:[`footer.page-text`]="props">
                <div>全 {{ props.itemsLength }} 件中 {{ props.pageStart }} 件 〜 {{ props.pageStop }} 件を表示</div>
              </template>
              <template v-slot:no-data>
                データがありません
              </template>
          </v-data-table>
        </div>

        <v-divider :style="{width: isSmartPhone ? '100%' : '80%'}"></v-divider>

      </div>

      <!-- ローディング -->
      <v-dialog v-model="loading" persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            読み込んでいます
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapMutations, mapActions } from "vuex";
import {
  GET_DELIVERY_READ_PARENTS,
  GET_CLASSROOM_ALL,
} from "@/store/action-types";
import {
} from "@/store/mutation-types";
import {
  NO_DATA_TEXT,
  NO_RESULT_TEXT,
  LOADING_TEXT,
  REPLY_BUTTON_NAME,
  MARK_AS_READ_BUTTON_NAME,
} from "@/constants";
import MessagePreview from "./deliveryMessages/MessagePreview.vue";

export default {
  name: "DeliveryCreation",
  props: {
    delivery: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loadingText: LOADING_TEXT,
      currentPage: 1,
      preview: false,
      readParentsList: [],
      readStudentsCount: 0,
      noDataText : NO_DATA_TEXT,
      noResultText: NO_RESULT_TEXT,
      loading: false,
      inputImageValue: [],
      positionInfo: 1,
      deliveryDetail: {
        deliveryName: "",
        messages: [],
      },
      /* 絵文字関連 */
      emojisPositionInfo: [
        {
          emojis: [],
          text: "",
        }
      ],
      selectedGrade: null,
      selectedClass: null,
      filteredClass: [],
      inputLastName: "",
      inputFirstName: "",
    };
  },
  components: {
    MessagePreview,
  },
  computed: {
    ...mapState({
      isSmartPhone: (state) => state.isSmartPhone,
      classroomAll: state => state.classroomAll,
    }),
    headers() {
      return [
        { text: "学年", value: "grade_name", filter: this.gradeFilter, width: "10%" },
        { text: "クラス", value: "class_name", filter: this.classFilter, width: "10%" },
        { text: "", value: "student_last_name", width: "0%", filter: this.lastNameFilter, sortable: false },
        { text: "氏名", value: "student_first_name", filter: this.firstNameFilter, width: "30%" },
        { text: "既読済保護者", value: "parent_first_name", width: "30%" },
        { text: "既読状態", value: "read_flag", width: "20%" },
      ];
    },
  },
  watch: {
    selectedGrade(value) {
      this.onGradeChange();
      if (!this.selectedGrade) {
        return true;
      }
      return value.toLowerCase().includes(this.selectedGrade.toLowerCase());
    },
  },
  methods: {
    onGradeChange() {
      // 学年に応じたクラスをフィルタリング
      this.filteredClass = this.classroomAll.filter(
        (item) => item.grade_name === this.selectedGrade
      );
      // クラス選択をリセット
      this.selectedClass = null;
    },
    gradeFilter(value) {
      if (!this.selectedGrade) {
        return true;
      }
      return (value === this.selectedGrade);
    },
    classFilter(value) {
      if (!this.selectedClass) {
        return true;
      }
      return (value === this.selectedClass);
    },
    lastNameFilter(value) {
      if (!this.inputLastName) {
        return true
      }
      return value.includes(this.inputLastName);
    },
    firstNameFilter(value) {
      if (!this.inputFirstName) {
        return true
      }
      return value.includes(this.inputFirstName);
    },
    formatToYYYYMMDDHHmmss(value) {
      return moment(value).subtract(9, 'hours').format('YYYY-MM-DD HH:mm:ss')
    },
    ...mapActions({
      getDeliveryReadParents: GET_DELIVERY_READ_PARENTS,
      getClassroomAll: GET_CLASSROOM_ALL,
    }),
    ...mapMutations({}),
    setData() {
      // propsの値をdetailに入れる
      this.deliveryDetail.deliveryName = this.delivery.delivery_name;
      this.deliveryDetail.messages = this.delivery.delivery_contents;
      
      for (let i = 0; i < this.deliveryDetail.messages.length; i++) {
        this.deliveryDetail.messages[i]["id"] = i;

        if (this.delivery?.delivery_contents?.[i]?.contents?.body?.contents?.[0]?.url != null)  {
          // pdfMsgの場合、detailとurl管理用の配列にURLを持たせなおす(DBに保存されたjsonオブジェクトを参照)
          this.inputImageValue[i] = [{ name: 'pdf' }]
          this.deliveryDetail.messages[i]["url"] = this.delivery.delivery_contents[i].contents.body.contents[0].url;
          this.deliveryDetail.messages[i]["pdfUrl"] = this.delivery.delivery_contents[i].contents.body.contents[0].action.uri;
          const aspectRatio = this.delivery.delivery_contents[i].contents.body.contents[0].aspectRatio;
          const splitAspectRatio = aspectRatio.split(':');
          this.deliveryDetail.messages[i]["imgWidth"] = splitAspectRatio[0];
          this.deliveryDetail.messages[i]["imgHeight"] = splitAspectRatio[1];
          this.deliveryDetail.messages[i].type = 'image';
          this.deliveryDetail.messages[i].previewImageUrl = this.delivery.delivery_contents[i].contents.body.contents[0].url
        } else if (this.deliveryDetail.messages[i]["type"] == "image") {
          // imageMsgの場合、detailとurl管理用の配列にURLを持たせなおす
          this.inputImageValue[i] = [{ name: 'image' }]
          this.deliveryDetail.messages[i]["url"] = this.delivery.delivery_contents[i]["originalContentUrl"]
        } else if (this.deliveryDetail.messages[i]["type"] == "flex") {
          // FlexMsgの場合、flexMessageキーを追加してcontentsを入れる
          this.deliveryDetail.messages[i].flexMessage = this.deliveryDetail.messages[i].contents;
        }

        /* 絵文字情報をセット */
        if (this.deliveryDetail.messages[i]["type"] == "text") {
          if (this.deliveryDetail.messages[i]["emojis"]) {
            const emojisPositionInfo = {
              emojis: this.deliveryDetail.messages[i]['emojis'],
            }
            this.emojisPositionInfo[i] = emojisPositionInfo;
          } else {
            const emojisPositionInfo = {
              emojis: [],
            }
            this.emojisPositionInfo[i] = emojisPositionInfo;
          }
        }
      }
    },
  },
  async created() {
    this.loading = true;

    await this.getClassroomAll();

    /* 配信に紐づく返信メッセージ一覧を取得する */
    this.readParentsList = await this.getDeliveryReadParents(this.$route.query.deliveryId);

    /* 生徒ごとの既読数を取得 */
    this.readStudentsCount = this.readParentsList.filter(student => student.read_flag.some(flag => flag === 1)).length;

    /* データセット */
    this.setData();

    /* 返信用ボタン、既読更新ボタンはプレビュー表示しない */
    this.deliveryDetail.messages = this.deliveryDetail.messages.filter(msg => {
      return msg.altText !== REPLY_BUTTON_NAME && 
             msg.altText !== MARK_AS_READ_BUTTON_NAME;
    });

    this.loading = false;
  },
  async mounted() {}
};
</script>
<style scoped>
.message-preview {
  position: relative;
  width: 30%;
}
.reply-message-table :deep(.v-data-table-header) {
  background-color: #CDE3FB;
}
.smartphone-data-table ::v-deep .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row{
  height: unset;
  min-height: 30px;
}
</style>
