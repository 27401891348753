<template>
  <div class="contentsArea">
    <v-dialog persistent v-if="firstEditDialog" v-model="firstEditDialog" max-width="600px">
      <v-card>
        <v-card-title>初回設定</v-card-title>
        <v-card-text>
          学年・クラスの数を決定してください。<br><br>
          ※名称は後から何度でも変更できます<br>
          ※学年・クラス数を変更するには、学年・クラス数のリセットが必要になります。
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="firstEditDialog = false">学年・クラスの作成に進む</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-if="secondEditDialog" v-model="secondEditDialog"  max-width="600px">
      <v-card>
        <v-card-title>名称設定</v-card-title>
        <v-card-text>
          学年・クラスの名称を決定してください。<br>
          ※名称は後から何度でも変更できます。
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="secondEditDialog = false">名称の編集に進む</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-if="resetDialog" v-model="resetDialog"  max-width="600px">
      <v-card>
        <v-card-title>学校情報リセット</v-card-title>
        <v-card-text>
          学年・クラスの情報がリセットされます。よろしいですか？<br>
          ※紐づく生徒が登録されている場合はリセットは行えません。
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" text color="primary" @click="resetDialog = false">キャンセル</v-btn>
          <v-btn :loading="loading" text color="red" @click="reset()">リセット</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-if="forceResetDialog" v-model="forceResetDialog"  max-width="600px">
      <v-card>
        <v-card-title>確認</v-card-title>
        <v-card-text>
          リセットしようとしているクラスに紐づく{{ forceResetDialogMsg }}がいます。<br>
          このままリセットしてよろしいですか？<br>
          リセットした場合、登録されている{{ forceResetDialogMsg }}の情報は利用できなくなります。
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" text color="primary" @click="closeForceResetDialog()">キャンセル</v-btn>
          <v-btn :loading="loading" text color="red" @click="forceReset()">リセット</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-form @submit.prevent v-model="isNotValid">
      <v-card>
        <v-dialog v-model="loading" persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              読み込んでいます
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
        <v-dialog v-if="saveDialog" v-model="saveDialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">学校情報保存</span>
            </v-card-title>
            <v-card-text>
              学校情報の保存を行います。<br>
              よろしいですか？<br>
              ※保存された学校情報は、該当年度中は編集が出来なくなります。
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :loading="loading" color="blue darken-1" text @click="saveDialog = false">
                キャンセル
              </v-btn>
              <v-btn :loading="loading" color="blue darken-1" text :disabled="!isNotValid" @click="save()">
                保存
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-toolbar style="height: 100px;" flat class="titleBar">
          <v-row>
            <v-card-title>クラス管理</v-card-title>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :loading="loading" v-if="!isFirstEditFlag && isEditModeFlag"
                @click="resetDialog = true" color="red white--text">
                リセット
              </v-btn>
              <v-btn :loading="loading" v-if="isFirstEditFlag && !isEditModeFlag" :disabled="(notDeleteSchoolItems()).length >= 9"
                @click="addGrade" color="green" outlined>
                学年を追加
              </v-btn>
              <v-btn :loading="loading" v-if="isFirstEditFlag && !isEditModeFlag" :disabled="(notDeleteSchoolItems()).length <= 1"
                @click="subtractGrade" color="red" outlined>
                学年を削除
              </v-btn>
              <v-btn :loading="loading" :disabled="!isNotValid" v-if="isEditModeFlag || isFirstEditFlag" color="green white--text"
                @click="saveDialog = true">保存</v-btn>
            </v-card-actions>
          </v-row>
        </v-toolbar>

        <!-- <v-card-actions>
          <v-card-title>
            クラス管理
          </v-card-title>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" v-if="!isFirstEditFlag && isEditModeFlag"
            @click="resetDialog = true" color="red white--text">
            リセット
          </v-btn>
          <v-btn :loading="loading" v-if="isFirstEditFlag && !isEditModeFlag" :disabled="(notDeleteSchoolItems()).length >= 9"
            @click="addGrade" color="green" outlined>
            学年を追加
          </v-btn>
          <v-btn :loading="loading" v-if="isFirstEditFlag && !isEditModeFlag" :disabled="(notDeleteSchoolItems()).length <= 1"
            @click="subtractGrade" color="red" outlined>
            学年を削除
          </v-btn>
          <v-btn :loading="loading" :disabled="!isNotValid" v-if="isEditModeFlag || isFirstEditFlag" color="green white--text"
            @click="saveDialog = true">保存</v-btn>
        </v-card-actions> -->

        <v-container pt-0 fluid>
          <div class="schoolSetting">
            <v-row dense>
              <v-col cols="4" v-for="item in notDeleteSchoolItems()" :key="item.id">
                <v-card height="33vh" class="itemsArea kawamitsu" elevation="4" v-if="!item.isDelete">
                  <v-toolbar dense style="position: sticky; top: 0; margin-top: 0%; z-index: 7;" elevation="2">
                    <!-- <v-row elevation="2" style="position: sticky; top: 0;"> -->
                    <v-col cols="3">
                      <v-btn v-if="false" :disabled="(notDeleteSchoolItems()).length < 2" color="red white--text"
                        @click="deleteGrade(item.id)">
                        削除
                      </v-btn>
                    </v-col>
                    <v-col cols="6">
                      <div class="school-grade-name-area">
                        <v-text-field style="margin-top: 0%;padding: none;" elevation="0" hide-details
                          :outlined="item.canEdit" :filled="!item.canEdit" :dark="!item.canEdit"
                          :background-color="item.canEdit ? '' : '#66BB6A'" :readonly="!item.canEdit" dense solo
                          maxlength="10" counter="10"
                          :append-icon="!isEditModeFlag ? '' : !item.canEdit ? 'mdi-pencil' : 'mdi-check'"
                          @click:append="gradeAppendClick(item.id), $forceUpdate()" v-model="item.school_grade"
                          :rules="[isNotCreatingRule(item.canEdit)]">
                        </v-text-field>
                      </div>
                    </v-col>
                    <v-col cols="3">
                      <v-toolbar dense elevation="0" v-if="isFirstEditFlag && !isEditModeFlag">
                        <v-btn elevation="0" class="mx-2" fab height="20px" width="20px" color="primary"
                          @click="addSchoolClass(item.id)" :disabled="disabledSchoolClass(item.school_class)">
                          <v-icon color="white">
                            mdi-plus
                          </v-icon>
                        </v-btn>
                        <v-btn elevation="0" class="mx-2" fab height="20px" width="20px" color="red"
                          @click="deleteSchoolClass(item.id)" :disabled="item.school_class.length == 1">
                          <v-icon color="white">
                            mdi-minus
                          </v-icon>
                        </v-btn>
                      </v-toolbar>
                    </v-col>
                    <!-- </v-row> -->
                  </v-toolbar>
                  <v-card-text>
                    <v-row dense>
                      <v-col v-for="(classroom, classIndex) in item.school_class" :key="classIndex" cols="4" align="center">
                        <div class="schoolClassroom">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on" class="tooltip">
                                <v-text-field
                                  v-model="item.school_class[classIndex].school_class"
                                  :readonly="!schoolItemCanEdit(item.id, classIndex)"
                                  :rules="[isNotCreatingRule(schoolItemCanEdit(item.id, classIndex))]"
                                  dense
                                  rounded
                                  hide-details
                                  maxlength="10"
                                  counter="10"
                                  :outlined="schoolItemCanEdit(item.id, classIndex)"
                                  :filled="!schoolItemCanEdit(item.id, classIndex)"
                                  :dark="!schoolItemCanEdit(item.id, classIndex)"
                                  :background-color="schoolItemCanEdit(item.id, classIndex) ? '' : '#66BB6A'"
                                  :append-icon="!isEditModeFlag ? '' : !schoolItemCanEdit(item.id, classIndex) ? 'mdi-pencil' : 'mdi-check'"
                                  @click:append="classAppendClick(item.id, classIndex), $forceUpdate()"
                                ></v-text-field>
                                <div>{{ `(${item.school_class[classIndex].grade_id}-${item.school_class[classIndex].classroom_id})` }}</div>
                              </span>
                            </template>
                            <span>
                              <div>{{ item.school_class[classIndex].school_class }}</div>
                              <div>{{ `(${item.school_class[classIndex].grade_id}-${item.school_class[classIndex].classroom_id})` }}</div>
                            </span>
                          </v-tooltip>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-container>
        <v-snackbar v-model="snackbar" multi-line>
          {{ snackbarText }}
        </v-snackbar>
      </v-card>
    </v-form>
  </div>
</template>
  
<script>
import Vue from "vue";
import {
  mapActions,
  mapState,
  mapMutations
} from "vuex";
import {
  GET_GRADE_CLASS_ALL,
  CREATE_SCHOOL_ITEMS,
  UPDATE_SCHOOL_ITEMS,
  RESET_SCHOOL_ITEMS,
  FORCE_RESET_SCHOOL_ITEMS
} from "@/store/action-types";
import {
  SET_SCHOOL_ITEMS,
  SET_NEED_CREATE_SCHOOL,
  SET_SEARCH_YEAR
} from "@/store/mutation-types";
import moment from 'moment';

export default Vue.extend({
  data() {
    return {
      forceResetDialog: false,
      forceResetDialogMsg: '',
      loading: false,
      beforeAppend: null,
      firstEditDialog: false,
      secondEditDialog: false,
      resetDialog: false,
      i: 0,
      menu: null,
      updateFlag: false,
      snackbar: false,
      snackbarText: null,
      schoolItems: [],
      isNotValid: false,
      schoolCategoryValue: null,
      createDialog: false,
      year: moment().subtract(3, 'months').year(),
      addDialog: false,
      saveDialog: false,
      isEditModeFlag: false,
      isFirstEditFlag: false,
      numbers: Array.from({ length: 10 }, (_, i) => i + 1),
      halfNumLength1: value => /^\d{1}$/.test(value) || '半角数字1桁以内で入力してください',
      halfNumLength2: value => /^\d{2}$/.test(value) || /^\d{1}$/.test(value) || '半角数字2桁以内で入力してください',
    };
  },
  computed: {
    ...mapState({
      gradeClassAll: state => state.gradeClassAll,
      needCreateSchool: state => state.setup.needCreateSchool,
      fiscalYear: state => state.fiscalYear,
    }),
  },
  watch: {
    fiscalYear() {
      this.isEditModeFlag = false,
      this.isFirstEditFlag = false,
      this.updateFlag = false,
      this.isRead();
    },
  },
  methods: {
    // resetBeforeAppend(nextAppend) {
    //   console.log(nextAppend)
      // 1つしか活性化しないようにしたい処理だけどうまく動いてない
      // 今実装しきる必要ないためコメント
      // if(this.beforeAppend != null){
      //   if (this.beforeAppend.category != nextAppend.category && this.beforeAppend.position != nextAppend.position){
      //     if (this.beforeAppend.category == "grade"){
      //       for (let i = 0; i < this.schoolItems.length; i++) {
      //         if (this.schoolItems[i].id === this.beforeAppend.position) {
      //           this.schoolItems[i].canEdit = !this.schoolItems[i].canEdit;
      //         }
      //       }
      //     } else if (this.beforeAppend.category == "classroom") {
      //       for (let i = 0; i < this.schoolItems.length; i++) {
      //         if (this.schoolItems[i].id === this.beforeAppend.position.id) {
      //           this.schoolItems[i].school_class[this.beforeAppend.position.index].canEdit = !this.schoolItems[i].school_class[this.beforeAppend.position.index].canEdit;
      //         }
      //       }
      //     }
      //   }
      // }
    // },
    classAppendClick(id, index) {
      for (let i = 0; i < this.schoolItems.length; i++) {
        if (this.schoolItems[i].id === id) {
          this.schoolItems[i].school_class[index].canEdit = !this.schoolItems[i].school_class[index].canEdit
        }
      }
      let nextAppend = {
        category : "classroom",
        position : {
          id : id,
          index : index
        }
      }

      // this.resetBeforeAppend(nextAppend)

      this.beforeAppend = nextAppend

      this.$forceUpdate()
    },
    gradeAppendClick(id) {
      for (let i = 0; i < this.schoolItems.length; i++) {
        if (this.schoolItems[i].id === id) {
          this.schoolItems[i].canEdit = !this.schoolItems[i].canEdit;
        }
      }
      let nextAppend = {
        category : "grade",
        position : id
      }

      // this.resetBeforeAppend(nextAppend)

      this.beforeAppend = nextAppend
      
      this.$forceUpdate()
    },
    notDeleteSchoolItems() {
      return this.schoolItems.filter(item => item.isDelete != true)
    },
    isNotCreatingRule(isEdit) {
      return (isEdit === undefined ? true : isEdit === false ? true : false)
    },
    schoolItemCanEdit(id, classIndex) {
      for (let i = 0; i < this.schoolItems.length; i++) {
        if (this.schoolItems[i].id === id) {

          return this.schoolItems[i].school_class[classIndex].canEdit
        }
      }
      return false
    },
    makeSchoolItemsFirst() {
      this.schoolItems = [];
      for (this.i = 1; this.i < 7; this.i++) {
        this.schoolItems.push({
          grade_id: (this.i).toString().padStart(2, '0'), // 数字を2桁まで0埋め
          school_grade: String(this.i) + '年',
          school_class: [{
            grade_id: (this.i).toString().padStart(2, '0'), // 数字を2桁まで0埋め
            classroom_id: "01",
            school_class: '1組',
            canEdit: false
          }],
          id: this.i
        });
      }
    },
    disabledSchoolClass(school_class) {
      const DELETE_SCHOOL_CLASS_LENGTH = (school_class.filter(item => item.isDelete == true)).length;
      return (school_class.length - DELETE_SCHOOL_CLASS_LENGTH > 98) ? true : false
    },
    ...mapActions({
      getGradeClassAll: GET_GRADE_CLASS_ALL,
      createSchoolItems: CREATE_SCHOOL_ITEMS,
      updateSchoolItems: UPDATE_SCHOOL_ITEMS,
      resetSchoolItems: RESET_SCHOOL_ITEMS,
      forceResetSchoolItems: FORCE_RESET_SCHOOL_ITEMS,
    }),
    ...mapMutations({
      setSchoolItems: SET_SCHOOL_ITEMS,
      setNeedCreateSchool: SET_NEED_CREATE_SCHOOL,
      setSearchYear: SET_SEARCH_YEAR,
    }),
    subtractGrade() {
      this.schoolItems.pop();
      this.i -= 1;
    },
    addGrade() {
      this.i++;
      this.schoolItems.push({
        grade_id: String(this.schoolItems.length + 1).padStart(2, '0'), // 数字を2桁まで0埋め
        school_grade: String(this.schoolItems.length + 1) + '年',
        school_class: [{
          grade_id: String(this.schoolItems.length + 1).padStart(2, '0'), // 数字を2桁まで0埋め
          classroom_id: '01',
          school_class: String(1) + '組',
          canEdit: false,
        }],
        id: this.i - 1
      })
    },
    addSchoolClass(id) {
      for (let i = 0; i < this.schoolItems.length; i++) {
        if (this.schoolItems[i].id === id) {
          this.schoolItems[i].school_class.push({
            grade_id: String(this.schoolItems[i].id).padStart(2, '0'), // 数字を2桁まで0埋め
            classroom_id: String(this.schoolItems[i].school_class.length + 1).padStart(2, '0'), // 数字を2桁まで0埋め
            school_class: String(this.schoolItems[i].school_class.length + 1) + '組',
            canEdit: false,
          })
        }
      }
    },
    deleteSchoolClass(id) {
      for (let i = 0; i < this.schoolItems.length; i++) {
        if (this.schoolItems[i].id === id) {
          this.schoolItems[i].school_class.pop()
        }
      }
    },
    deleteGrade(id) {
      for (let i = 0; i < this.schoolItems.length; i++) {
        if (this.schoolItems[i].id === id) {
          this.schoolItems[i].isDelete = true;
        }
      }
      this.$forceUpdate()
    },
    async isRead() {
      this.loading = true;
      this.updateFlag = false
      await this.getGradeClassAll();
      if (this.gradeClassAll.grade.length == 0 && this.gradeClassAll.class.length == 0) {
        //ない場合は初期作成に入る
        this.isFirstEditFlag = true
        this.isEditModeFlag = false
        this.makeSchoolItemsFirst()
        this.firstEditDialog = true
        this.loading = false;
        return false
      } else {
        //ある場合は表示
        this.isFirstEditFlag = false
        this.makeSchoolItems()
        this.loading = false;
        return true
      }
    },
    async makeSchoolItems() {
      this.schoolItems = []
      for (this.i = 0; this.gradeClassAll.grade.length > this.i; this.i++) {
        let targetClass = this.gradeClassAll.class.filter(classroom => classroom.grade_id === this.gradeClassAll.grade[this.i].grade_id);
        this.schoolItems.push({
          id: this.i,
          grade_id: this.gradeClassAll.grade[this.i].grade_id,
          school_grade: this.gradeClassAll.grade[this.i].school_grade,
          school_class: targetClass,
        })
      }
      // if (this.year != this.fiscalYear) {
      if (this.fiscalYear != null) {
        //今年度でない場合は編集可能
        //20230406追記 今年度も編集可能にしとく TODO これは一時的なものなので戻す
        this.isEditModeFlag = true;
        this.updateFlag = true;
      }
    },
    async reset() {
      this.loading=true;
      const res = await this.resetSchoolItems();
      // console.log('リセットのログ', res)
      if (res != undefined && (res.status == 200 && res.data.statusCode == 200)) {
        this.resetDialog = false
        this.isRead()
        this.snackbar = true,
          this.snackbarText = '情報のリセットに成功しました。'
      } else if(res.data.errCode){
        this.openForceResetDialog(res.data.errCode)
      } else {
        this.snackbar = true,
        this.snackbarText = '不明なエラーが発生したため、情報のリセットに失敗しました。'
      }
      this.loading=false;
    },
    async forceReset() {
      this.loading=true;
      const res = await this.forceResetSchoolItems();
      if (res != undefined && (res.status == 200 && res.data.statusCode == 200)) {
        this.forceResetDialog = false
        this.isRead()
        this.snackbar = true,
        this.snackbarText = '情報のリセットに成功しました。'
      } else {
        this.snackbar = true,
        this.snackbarText = '不明なエラーが発生したため、情報のリセットに失敗しました。'
      }
      this.loading=false;
    },
    openForceResetDialog(err) {
      this.resetDialog = false;
      this.forceResetDialog = true;
      if(err == 'student'){
        this.forceResetDialogMsg = '生徒'
      } else if (err == 'staff') {
        this.forceResetDialogMsg = '職員'
      } else if (err == 'student-staff') {
        this.forceResetDialogMsg = '生徒と職員'
      }
    },
    closeForceResetDialog() {
      this.forceResetDialog = false;
      this.forceResetDialogMsg = ''
    },
    async save() {
      this.loading = true;
      this.setSchoolItems({
        schoolItems: this.schoolItems,
        year: this.fiscalYear
      });
      let res = null;
      if (this.updateFlag == false) {
        res = await this.createSchoolItems();
      } else {
        res = await this.updateSchoolItems();
      }
      // console.log('res', res)
      if (res != undefined || (res.status == 200 && res.data.statusCode == 200)) {
        if(this.isFirstEditFlag == true){
          this.secondEditDialog = true
        }
        this.isFirstEditFlag = false
        this.isEditModeFlag = false
        this.saveDialog = false
        this.setNeedCreateSchool(false);
        this.isRead()
        this.snackbar = true,
          this.snackbarText = '情報の登録に成功しました。'
      } else {
        this.snackbar = true,
          this.snackbarText = '不明なエラーが発生したため、情報の登録に失敗しました。'
      }
      this.loading = false;
    },
    cancelAddDialog() {
      this.setSearchYear(moment().subtract(3, 'months').year())
      this.addDialog = false;
      this.isEditModeFlag = false;
    }
  },
  created() {
    this.isRead()
  },
  mounted() { }
});
</script>
  
<style scoped>
.v-text-field--outlined>>>fieldset {
  border-color: #66BB6A;
}
</style>
<style>
.schoolClassroom>.tooltip>.v-input>.v-input__control>.v-input__slot>.v-input__append-inner>.v-input__icon--append>.v-icon {
  font-size: 15px !important;
  margin-right: 10px !important;
}

.schoolClassroom>.tooltip>.v-input>.v-input__control>.v-input__slot {
  padding-left: 10px;
  padding-right: 0px;
}

.schoolClassroom>.tooltip>.v-input>.v-input__control>.v-input__slot>.v-text-field__slot>input {
  width: 50px !important;
  text-align: center;
}

.kawamitsu::-webkit-scrollbar {
  display: none !important;
}

.kawamitsu {
  overflow: auto !important;
}

.kawamitsu>.v-card__text {
  padding-top: 10px;
}

.kawamitsu>.v-sheet>.v-toolbar__content>.col {
  padding-bottom: 0px;
  padding-top: 0px;
}

.school-grade-name-area>.v-input>.v-input__control>.v-input__slot>.v-input__append-inner>.v-input__icon--append>.v-icon {
  font-size: 15px !important;
  margin-right: 10px !important;
}

.school-grade-name-area>.v-input>.v-input__control>.v-input__slot {
  padding-left: 10px;
  padding-right: 0px;
}

.school-grade-name-area>.v-input>.v-input__control>.v-input__slot>.v-text-field__slot > input {
  
}

.school-grade-name-area>.v-input>.v-input__control>.v-input__slot>.v-text-field__slot>input {
  text-align: center;
  width: 50px !important;
}

.school-grade-name-area>.v-input>.v-input__control>.v-input__slot {
  padding: 0 !important;
}
</style>