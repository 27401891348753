<template>
  <div class="contentsArea">

    <v-card>
      <v-toolbar flat style="height:120px;" class="titleBar">
        <v-row>
          <v-card-title>生徒グループ一覧</v-card-title>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :loading="loading" color="primary" @click="openCreateStudentGroupDialog()">グループ追加</v-btn>
          </v-card-actions>
        </v-row>
      </v-toolbar>
      
      <v-data-table
        :loading="loading"
        :loading-text="loadingText"
        :no-data-text="noDataText"
        :no-results-text="noResultText"
        :headers="headers"
        :items="allStudentGroupList"
        sort-by="school_grade"
        class="elevation-1"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, -1], 'items-per-page-text': '行/ページ:' }"
      >
        <!-- <template v-slot:[`item.student_in_group`]="{ item }">
          <v-btn @click="openStudentListDialog(item)" color="primary" outlined v-if="item.is_delete == 1 ? false : true">
            {{ createStudentNameListByStudentGroupId(item.student_group_id) ? createStudentNameListByStudentGroupId(item.student_group_id).length : 0 }}名所属
          </v-btn>
        </template>
        <template v-slot:[`item.editBtn`]="{ item }">
          <div v-if="item.is_delete == 0">
            <v-col class="pr-0 pl-0" align="center">
              <v-btn color="green white--text" class="mr-2" @click="openEditItem(item)">編集</v-btn>
            </v-col>
          </div>
        </template>
        <template v-slot:[`item.deleteBtn`]="{ item }">
          <div v-if="item.is_delete == 0">
            <v-col class="pl-0 pr-0" align="center">
              <v-btn color="red white--text" class="mr-2" @click="openInvalidDialog(item)">無効</v-btn>
            </v-col>
          </div>
          <div v-else style="align-content: center;">
            <v-col align="center">
              <v-chip color="grey" text-color="white">
                無効
              </v-chip>
            </v-col>
          </div>
        </template> -->
        

        <template v-slot:[`item.student_in_group`]="{ item }">
          <v-btn @click="openStudentListDialog(item)" color="primary" outlined v-if="item.is_delete == 1 ? false : true">
            {{ createStudentNameListByStudentGroupId(item.student_group_id) ? createStudentNameListByStudentGroupId(item.student_group_id).length : 0 }}名所属
          </v-btn>
        </template>
        <template v-slot:[`item.editBtn`]="{ item }">
          <div v-if="item.is_delete == 0">
            <v-col align="center" class="px-0">
              <v-btn color="green white--text" class="mr-2" @click="openEditItem(item)">編集</v-btn>
            </v-col>
          </div>
        </template>
        <template v-slot:[`item.deleteBtn`]="{ item }">
          <div v-if="item.is_delete == 0">
            <v-col align="center" class="px-0">
              <v-btn color="red white--text" class="mr-2" @click="openInvalidDialog(item)">無効</v-btn>
            </v-col>
          </div>
          <div v-else style="align-content: center;">
            <v-col align="center" class="px-0">
              <v-chip color="grey" text-color="white">
                無効
              </v-chip>
            </v-col>
          </div>
        </template>
        <!-- <template v-slot:[`item`]="{ item }">
          <tr :style="{ backgroundColor: (item.is_delete == 1) ? '#CCC' : 'transparent' }">
            <td>{{ item.student_group_id }}</td>
            <td>{{ item.student_group_name }}</td>
            <td>{{ item.student_group_detail }}</td>
            <td>
              <v-btn @click="openStudentListDialog(item)" color="primary" outlined v-if="item.is_delete == 1 ? false : true">
                {{ createStudentNameListByStudentGroupId(item.student_group_id) ? createStudentNameListByStudentGroupId(item.student_group_id).length : 0 }}名所属
              </v-btn>
            </td>
            <td>{{ item.created_at }}</td>
            <td class="pa-0">
              <div v-if="item.is_delete == 0">
                <v-col class="pr-0 pl-0" align="center">
                  <v-btn color="green white--text" class="mr-2" @click="openEditItem(item)">編集</v-btn>
                </v-col>
              </div>
            </td>
            <td class="pa-0">
              <div v-if="item.is_delete == 0">
                <v-col class="pl-0 pr-0" align="center">
                  <v-btn color="red white--text" class="mr-2" @click="openInvalidDialog(item)">無効</v-btn>
                </v-col>
              </div>
              <div v-else style="align-content: center;">
                <v-col align="center">
                  <v-chip color="grey" text-color="white">
                    無効
                  </v-chip>
                </v-col>
              </div>
            </td>
          </tr>
        </template> -->
        <template v-slot:[`footer.page-text`]="props">
          <div>全 {{ props.itemsLength }} 件中 {{ props.pageStart }} 件 〜 {{ props.pageStop }} 件を表示</div>
        </template>
        <template v-slot:no-data>
          データがありません
        </template>
      </v-data-table>

    </v-card>
    
    <!-- 生徒をグループに追加するダイアログ -->
    <v-dialog v-if="addStudentGroupDialog" v-model="addStudentGroupDialog" width="600">
      <v-card>
        <v-card-title>生徒追加</v-card-title>
        <v-card-text>
          グループ {{ studentListCurrentGroup.student_group_name }} に追加する生徒を選択してください
          <v-autocomplete chips v-model="addStudentIdList" multiple :item-text="item => item.classroom.school_grade + item.classroom.school_class + ' ' + item.last_name + item.first_name" :item-value="item => item.student_id" :items="addStudentGroupStudentItem(studentListCurrentGroup.student_group_id)">
            <template v-slot:no-data>
              データがありません
            </template>
          </v-autocomplete>
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="addStudentGroupDialog = false">キャンセル</v-btn>
          <v-btn color="primary" :disabled="addStudentIdList.length < 1" @click="addConfirmDialog = true">追加</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- グループに所属する生徒リスト表示ダイアログ -->
    <v-dialog v-if="studentListDialog" v-model="studentListDialog" persistent max-width="700px"> 
      <v-card>
        <v-card-title>
          {{ studentListCurrentGroup.student_group_name }}  所属生徒一覧
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :loading="loading" color="primary" outlined
              @click="openAddStudentGroupDialog()">
              生徒追加
            </v-btn>
            <v-btn :loading="loading" color="green" dark @click="studentListDialog = false">閉じる</v-btn>
          </v-card-actions>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container>
            <div>
              <v-card-text class="pt-0">
                <div v-if="studentList.length == 0">
                  現在所属している生徒はいません。
                </div>
                <div v-else>
                  <v-row>
                    <v-col>
                      <v-autocomplete :items="classroomAll" :item-text="item => (item.school_grade + ' ' + item.school_class)"
                        item-value="classroom_id" v-model="classroomFilterValue" clearable hide-details label="クラス">
                        <template v-slot:no-data>
                          <div class="px-4">データがありません</div>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="studentNameSeiFilterValue" label="氏名（姓）">
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="studentNameMeiFilterValue" label="氏名（名）">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-data-table
                    v-if="studentList.length != 0"
                    :loading="loading"
                    :loading-text="loadingText"
                    :no-data-text="noDataText"
                    :no-results-text="noResultText"
                    :headers="headers2"
                    :items="studentList"
                    sort-by="school_grade"
                    class="paddingHarf"
                    :footer-props="{ 'items-per-page-options': [5, 30, 50, 100, -1], 'items-per-page-text': '行/ページ:' }"
                  >
                    <template v-slot:[`item.classroom.school_grade`]="{item}">
                      {{ item.classroom.school_grade + ' ' + item.classroom.school_class }}
                    </template>
                    <template v-slot:[`item.classroom.school_class`]="{}">
                    </template>
                    <template v-slot:[`item.first_name`]="{}">
                    </template>
                    <template v-slot:[`item.last_name`]="{ item }">
                      <div>{{ item.last_name + ' ' + item.first_name }}</div>
                    </template>
                    <template v-slot:[`item.last_name_kana`]="{ item }">
                      <div>{{ item.last_name_kana + ' ' + item.first_name_kana }}</div>
                    </template>
                    <template v-slot:[`item.deleteBtn`]="{ item }">
                      <v-col align="center" class="px-0">
                        <v-btn color="red" outlined @click="deleteConfirmDialog = true, studentListCurrentGroup.student_id = item.student_id">削除</v-btn>
                      </v-col>
                    </template>
                    <template v-slot:[`footer.page-text`]="props">
                      <div>全 {{ props.itemsLength }} 件中 {{ props.pageStart }} 件 〜 {{ props.pageStop }} 件を表示</div>
                    </template>
                    <template v-slot:no-data>
                      データがありません
                    </template>
                  </v-data-table>
                  <v-divider></v-divider>
                </div>
              </v-card-text>
            </div>
          </v-container>

        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ローディングダイアログ -->
    <v-dialog v-model="loading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          読み込んでいます
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- 無効化ダイアログ -->
    <v-dialog v-if="invalidItemDialog" v-model="invalidItemDialog" width="600">
      <v-card>
        <v-card-title>グループ無効化</v-card-title>
        <v-card-text>
          グループ「 {{ invalidItem.student_group_name }} 」を無効化します。<br>
          よろしいですか？
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="invalidItemDialog = false">キャンセル</v-btn>
          <v-btn color="primary" @click="invalidStudentGroupItem()">無効化</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 削除ダイアログ -->
    <v-dialog v-if="deleteStudentGroupDialog" v-model="deleteStudentGroupDialog" width="600">
      <v-card>
        <v-card-title>グループ削除</v-card-title>
        <v-card-text>
          グループ「{{ getGroupNameByGroupId(deleteStudentGroupId) }}」を削除します。<br>
          よろしいですか？
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="deleteStudentGroupDialog = false">キャンセル</v-btn>
          <v-btn color="primary" @click="deleteStudentGroupByGroupId()">削除</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 新規グループ追加ダイアログ -->
    <v-dialog v-if="createStudentGroupDialog" v-model="createStudentGroupDialog" width="600">
      <v-card>
        <v-card-title>グループ追加</v-card-title>
        <v-card-text>
          <v-form @submit.prevent v-model="isNotValid">
          <v-text-field v-model="createStudentGroupName.group_name" label="グループ名" counter="10" :rules="[required(), max_length_10]">
          </v-text-field>
        </v-form>
        <v-textarea
            class="pt-6"
            outlined
            label="グループ説明"
            v-model="createStudentGroupName.student_group_detail"
            counter="30"
            maxlength="30"
          ></v-textarea>
          {{ errMsg }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" color="primary" outlined @click="createStudentGroupDialog = false">キャンセル</v-btn>
          <v-btn :loading="loading" color="primary" 
            :disabled="(createStudentGroupName.group_name ? (createStudentGroupName.group_name != '' && createStudentGroupName.group_name != null && createStudentGroupName.group_name != undefined) ? false : true : true)"
            @click="saveCreateGroupDialog = true">
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 既存グループ編集ダイアログ -->
    <v-dialog v-if="editStudentGroupDialog" v-model="editStudentGroupDialog" width="600">
      <v-card>
        <v-card-title>グループ編集</v-card-title>
        <v-card-text>
          <v-form @submit.prevent v-model="isNotValid">
          <v-text-field v-model="editItem.student_group_name" label="グループ名" counter="10" maxlength="10" :rules="[required(), max_length_10]">
          </v-text-field>
          <v-textarea
            class="pt-6"
            outlined
            label="グループ説明"
            v-model="editItem.student_group_detail"
            counter="30"
            maxlength="30"
          ></v-textarea>
        </v-form>
        {{ errMsg }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" color="primary" outlined @click="editStudentGroupDialog = false">キャンセル</v-btn>
          <v-btn :loading="loading" color="primary" :disabled="!isNotValid" @click="editConfirmDialog = true">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 追加確認ダイアログ -->
    <v-dialog v-if="addConfirmDialog" v-model="addConfirmDialog" width="600">
      <v-card>
        <v-card-text class="pt-5">生徒をグループに追加します。よろしいですか？</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" color="primary" outlined @click="addConfirmDialog = false">キャンセル</v-btn>
          <v-btn :loading="loading" color="primary" @click="addStudent()">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 削除確認ダイアログ -->
    <v-dialog v-if="deleteConfirmDialog" v-model="deleteConfirmDialog" width="600">
      <v-card>
        <v-card-text class="pt-5">生徒をグループから削除します。よろしいですか？</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" color="primary" outlined @click="deleteConfirmDialog = false">キャンセル</v-btn>
          <v-btn :loading="loading" color="primary" @click="deleteRelationGroup(studentListCurrentGroup.student_group_id, studentListCurrentGroup.student_id)">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 編集確認ダイアログ -->
    <v-dialog v-if="editConfirmDialog" v-model="editConfirmDialog" width="600">
      <v-card>
        <v-card-text class="pt-5">グループの編集を完了します。よろしいですか？</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" color="primary" outlined @click="editConfirmDialog = false">キャンセル</v-btn>
          <v-btn :loading="loading" color="primary" @click="update()">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- グループ追加確認ダイアログ -->
    <v-dialog v-if="saveCreateGroupDialog" v-model="saveCreateGroupDialog" width="600">
      <v-card>
        <v-card-text class="pt-5">グループを作成します。よろしいですか？</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" color="primary" outlined @click="saveCreateGroupDialog = false">キャンセル</v-btn>
          <v-btn :loading="loading" color="primary" @click="createStudentGroupNew()">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
import moment from 'moment';
import { mapState, mapMutations, mapActions } from "vuex";
import {
  GET_STUDENTS_ALL,

  GET_STUDENT_GROUP_ALL,
  DELETE_STUDENT_GROUP,
  CREATE_STUDENT_GROUP,
  ADD_STUDENT_LIST_STUDENT_GROUP,
  DELETE_RELATION_STUDENT_GROUP,
  INVALID_STUDENT_GROUP,
  UPDATE_STUDENT_GROUP,
  GET_CLASSROOM_ALL,
} from "@/store/action-types";
import { } from "@/store/mutation-types";
import {
  AUTHORITIES,
  NO_DATA_TEXT,
  REQUIRED_ERROR_MSG,
  MAX_LENGTH_10_ERROR_MSG,
  LOADING_TEXT,
  NO_RESULT_TEXT,
} from "@/constants";
export default {
  name: "StudentGroupSettings",
  data: () => ({
    addStudentIdList: [],
    isNotValid: false,
    noDataText: NO_DATA_TEXT,
    allStudentGroupList: [],
    authorities: AUTHORITIES,
    loadingText: LOADING_TEXT,
    noResultText: NO_RESULT_TEXT,
    student: null,
    loading: false,
    deleteStudentGroupDialog: false,
    deleteStudentGroupId: null,
    createStudentGroupName: {},
    createStudentGroupDialog: false,
    editStudentGroupDialog: false,
    editConfirmDialog: false,
    editItem : null,
    invalidItemDialog: false,
    invalidItem: null,
    studentListCurrentGroup: null,
    studentListDialog: false,
    addStudentGroupDialog: false,
    addConfirmDialog: false,
    deleteConfirmDialog: false,
    saveCreateGroupDialog: false,
    studentList: [],
    max_length_10: value => value.length <= 10 || MAX_LENGTH_10_ERROR_MSG,
    errMsg: null,
    classroomFilterValue: '',
    SearchSchoolGrade: '',
    SearchSchoolClass: '',
    studentNameSeiFilterValue: '',
    studentNameMeiFilterValue: '',
  }),
  computed: {
    ...mapState({
      studentsAll: state => state.studentsAll,
      fiscalYear: state => state.fiscalYear,
      classroomAll: state => state.classroomAll,
    }),
    headers() {
      return [
        { text: "グループID", value: "student_group_id" ,width: '20%'},
        { text: "グループ名", value: "student_group_name" ,width: '20%'},
        { text: "グループ説明", value: "student_group_detail", width: '20%' },
        { text: "所属人数", value: "student_in_group",sortable: false ,width: '20%'},
        { text: "登録日時", value: "created_at" ,width: '20%'},
        { text: "", value: "editBtn", sortable: false ,width:'100px'},
        { text: "", value: "deleteBtn", sortable: false ,width:'100px'},
      ]
    },
    headers2() {
      return [
        { text: "クラス", value: "classroom.school_grade", filter: this.SearchGradeFilter, width: '30%' },
        { text: "", value: "classroom.school_class", filter: this.SearchClassFilter, width: '0%' },
        { text: "氏名", value: "last_name", filter: this.studentNameSeiFilter, width: '30%' },
        { text: "", value: "first_name", filter: this.studentNameMeiFilter, width: '0%' },
        { text: "氏名(かな・カナ)", value: "last_name_kana", width: '30%' },
        { text: "", value: "deleteBtn", sortable: false, width: '10%' },
      ]
    },
  },
  watch: {
    studentsAll: {
      handler: function () {
        this.students = [...this.studentsAll].filter(student => !student.is_delete);
      },
      deep: true
    },
    addStudentGroupDialog() {
      this.addStudentIdList = []
    },
    allStudentGroupList() {
      for (let i = 0; i < this.allStudentGroupList.length; i++){
        this.allStudentGroupList[i].created_at = moment(this.allStudentGroupList[i].created_at).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    async fiscalYear() {
      const res = await this.getStudentGroupAll();
      if (res.body == '200') {
        this.allStudentGroupList = res.result.filter(s => !s.is_delete);
      }
    },
    createStudentGroupDialog() {
      this.errMsg = null;
    },
    editStudentGroupDialog() {
      this.errMsg = null;
    },
    classroomFilterValue(classroom_id) {
      if (classroom_id != undefined && classroom_id != null) {
        let targetClassroom = this.classroomAll.find(classroom => classroom.classroom_id === classroom_id);
        this.SearchSchoolGrade = targetClassroom.school_grade;
        this.SearchSchoolClass = targetClassroom.school_class;
      } else {
        this.SearchSchoolGrade = null;
        this.SearchSchoolClass = null;
      }
    },
  },
  async created() {
    this.loading = true;
    const res = await this.getStudentGroupAll();
    if (res.body == '200') {
      this.allStudentGroupList = res.result.filter(s => !s.is_delete);//削除ずみの人は配列に代入しない。
    }
    await this.getStudentsAll();
    this.student = [...this.studentsAll];
    await this.getClassroomAll();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getStudentGroupAll: GET_STUDENT_GROUP_ALL,
      getStudentsAll: GET_STUDENTS_ALL,
      deleteStudentGroup: DELETE_STUDENT_GROUP,
      createStudentGroup: CREATE_STUDENT_GROUP,
      addStudentListStudentGroup: ADD_STUDENT_LIST_STUDENT_GROUP,
      deleteRelationStudentGroup: DELETE_RELATION_STUDENT_GROUP,
      invalidStudentGroup: INVALID_STUDENT_GROUP,
      updateStudentGroup: UPDATE_STUDENT_GROUP,
      getClassroomAll: GET_CLASSROOM_ALL,
    }),
    ...mapMutations({
    }),
    SearchGradeFilter(value) {
      if (!this.SearchSchoolGrade) {
        return true
      }
      return value.includes(this.SearchSchoolGrade)
    },
    SearchClassFilter(value) {
      if (!this.SearchSchoolClass) {
        return true
      }
      return value.includes(this.SearchSchoolClass);
    },
    studentNameSeiFilter(value) {
      if (!this.studentNameSeiFilterValue) {
        return true
      }
      return value.includes(this.studentNameSeiFilterValue);
    },
    studentNameMeiFilter(value) {
      if (!this.studentNameMeiFilterValue) {
        return true
      }
      return value.includes(this.studentNameMeiFilterValue);
    },
    addStudentGroupStudentItem(group_id) {
      return this.student.filter(item => (!item.student_group.includes(group_id) && item.authority != 1 && item.is_delete == false));
    },
    async openAddStudentGroupDialog() {
      this.loading = true;
      this.addStudentGroupDialog = true     
      this.loading = false;
    },
    async openStudentListDialog(item) {
      this.loading = true;
      this.studentListCurrentGroup = {...item}
      this.studentList = await this.createStudentNameListByStudentGroupId(this.studentListCurrentGroup.student_group_id)
      this.studentListDialog = true;
      this.loading = false;
    },
    async openInvalidDialog(item) {
      console.log(item)
      this.loading = true;
      this.invalidItem = {...item};
      this.invalidItemDialog = true;
      this.loading = false;
    },
    async invalidStudentGroupItem() {
      this.loading = true;
      await this.invalidStudentGroup(this.invalidItem)
      const res = await this.getStudentGroupAll();
      if (res.body == '200') {
        this.allStudentGroupList = res.result.filter(s => !s.is_delete);
      }
      this.invalidItemDialog = false;
      this.loading = false;
    },
    async addStudent(){
      this.loading = true;
      await this.addStudentListStudentGroup({
        student_group_id: this.studentListCurrentGroup.student_group_id, 
        student_id_list : this.addStudentIdList })
      const res = await this.getStudentGroupAll();
      if (res.body == '200') {
        this.allStudentGroupList = res.result.filter(s => !s.is_delete);
      }
      this.invalidItemDialog = false;

      await this.getStudentsAll();
      this.student = [...this.studentsAll];
      this.addStudentGroupDialog = false;
      this.addConfirmDialog=false;
      this.openStudentListDialog(this.studentListCurrentGroup)
    },
    async deleteRelationGroup(student_group_id ,student_id) {
      this.loading = true;
      await this.deleteRelationStudentGroup({student_group_id ,student_id})

      const res = await this.getStudentGroupAll();
      if (res.body == '200') {
        this.allStudentGroupList = res.result.filter(s => !s.is_delete);
      }
      this.invalidItemDialog = false;

      await this.getStudentsAll();
      this.student = [...this.studentsAll];
      this.deleteConfirmDialog = false;
      this.openStudentListDialog(this.studentListCurrentGroup)
    },
    async openEditItem(item) {
      console.log(item)
      this.loading = true;
      this.editItem = {...item};
      this.editStudentGroupDialog = true;
      this.loading = false;
    },
    openCreateStudentGroupDialog() {
      this.createStudentGroupName = {}
      this.createStudentGroupDialog = true;
    },
    getGroupNameByGroupId(deleteStudentGroupId) {
      const targetGroup = this.allStudentGroupList.find(studentGroup => studentGroup.student_group_id == deleteStudentGroupId)
      return targetGroup.student_group_name
    },
    openDeleteStudentGroupDialog(student_group_id) {
      this.deleteStudentGroupDialog = true;
      this.deleteStudentGroupId = student_group_id;
    },
    async update() {
      this.loading = true;
      const updateGroupRes = await this.updateStudentGroup(this.editItem)
      if (updateGroupRes.body == '500' && updateGroupRes.err == 'same-name') {
        this.errMsg = '既に同じ名前の生徒グループが存在します。';
      } else {
        const res = await this.getStudentGroupAll();
        if (res.body == '200') {
          this.allStudentGroupList = res.result.filter(s => !s.is_delete);
        }
        this.editStudentGroupDialog = false;
      }
      this.editConfirmDialog = false;
      this.loading = false;
    },
    async createStudentGroupNew() {
      this.loading = true;
      const createGroupRes = await this.createStudentGroup(this.createStudentGroupName);
      if (createGroupRes.body == '500' && createGroupRes.err == 'same-name') {
        this.errMsg = '既に同じ名前の生徒グループが存在します。';
      } else {
        const res = await this.getStudentGroupAll();
        if (res.body == '200') {
          this.allStudentGroupList = res.result.filter(s => !s.is_delete);
        }
        this.createStudentGroupDialog = false;
      }
      this.saveCreateGroupDialog = false;
      this.loading = false;
    },
    async deleteStudentGroupByGroupId() {
      this.loading = true;
      await this.deleteStudentGroup(this.deleteStudentGroupId)
      const res = await this.getStudentGroupAll();
      if (res.body == '200') {
        this.allStudentGroupList = res.result.filter(s => !s.is_delete);
      }
      this.deleteStudentGroupDialog = false;
      this.loading = false;
    },
    createStudentNameListByStudentGroupId(student_group_id) {
      if (this.student != null && this.student != undefined && this.student != ''){
        const targetStudentList = this.student.filter(student => student.student_group.includes(student_group_id));
        const targetStudentNameList = []
        for (let i in targetStudentList) {
          targetStudentNameList.push(targetStudentList[i])
        }
        return targetStudentNameList
      } else {
        return []
      }
    },
    required() {
      return value => !!value && !this.isSpace(value) || "グループ名" + REQUIRED_ERROR_MSG;
    },
    isSpace(v) {
      v = String(v)
      if(v == undefined || v == null){
        return true
      }
      for (let i = 0; i < v.length; i++) {
        const code = v.charCodeAt(i);
        if (code === 0x3000) { // Unicodeのコードポイントを使って全角スペースを判定
          continue; // 全角スペースの場合はループを継続
        }
        if (code === 0x20) { // 半角スペースの場合はループを継続
          continue;
        }
        return false; // それ以外の文字が含まれている場合はfalseを返す
      }
      return true; // 全角スペースか半角スペースしか含まれていない場合はtrueを返す
    }
  }
};
</script>