<template>
  <div class="SigninArea" v-on:keydown.enter="onEnterDown">
    <v-form v-model="isNotValid" @submit.prevent>
      <v-card class="mx-auto mt-5">
        <v-card-title>
          <v-spacer></v-spacer>
          ログイン
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-text-field prepend-inner-icon="mdi-account-circle" solo placeholder="ユーザ名" v-model="name" :rules="[noUserName]" />
        </v-card-text>
        <v-card-text>
          <v-text-field v-bind:type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword"
            prepend-inner-icon="mdi-lock" solo v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" placeholder="パスワード"
            v-model="password" :rules="[noPassword]" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <router-link :to="{ name: 'MainView' }" class="list-item-link"> -->
          <v-btn prepend-inner-icon="mdi-lock" :loading="loading" class="info" @click="submit" :disabled="!isNotValid">
            <v-icon left>
              mdi-login
            </v-icon>
            ログイン
          </v-btn>
          <!-- </router-link> -->
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-container>
          <p style="color:red" class="errMsg" v-if="errMsg != ''">{{ errMsg }}</p>
        </v-container>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import {
  LOGIN_TRUE,
  LOGIN_CHECK,
  LOGGED_TRUE
} from "@/store/action-types";
import { mapState, mapMutations, mapActions } from "vuex";
import {
  SET_AUTH,
} from "@/store/mutation-types";
import { LOGIN_ERROR_MSG } from "@/constants";
export default {
  name: 'SignInIndex',
  data() {
    return {
      showPassword: false,
      name: '',
      loading: false,
      password: '',
      errMsg: '',
      isNotValid: false,
      noPassword: v => !!v || "パスワードを入力して下さい",
      noUserName: v => !!v || "ユーザー名を入力して下さい",
    }
  },
  computed: {
    ...mapState({
      isLogin: (state) => state.isLogin,
      auth: (state) => state.auth,
      loginUser: (state) => state.loginUser,
    }),
  },
  methods: {
    ...mapActions({
      loginTrue: LOGIN_TRUE,
      loginCheck: LOGIN_CHECK,
      loggedTrue: LOGGED_TRUE
    }),
    ...mapMutations({
      setAuth: SET_AUTH,
    }),
    async submit() {
      this.loading = true;
      this.errMsg = "";
      const auth = {
        login_id: this.name,
        password: this.password,
      }
      this.setAuth(auth);
      const loginCheckResult = await this.loginCheck();
      // console.log('loginCheckResultだよ', loginCheckResult)
      if (loginCheckResult.statusCode != 200) {
        if (loginCheckResult.body == 'year-not-match') {
          this.errMsg = '有効な年度でないユーザーです。';
        } else if (loginCheckResult.body == 'delete-staff') {
          this.errMsg = 'このユーザー情報は無効化されているため、ログイン出来ません。';
        } else if (loginCheckResult.body == 'no-authority-staff') {
          this.errMsg = 'このユーザーにはログイン権限がないため、ログインできません。';
        } else {
          this.errMsg = LOGIN_ERROR_MSG;
        }
      } else {
        const isLogin = loginCheckResult.isLogin;
        const isLogged = loginCheckResult.isLogged;
        if (isLogin) {
          if (isLogged) {
            this.errMsg = "";
            this.loginTrue();
            this.loggedTrue();
            // 遷移
            const isSmartPhone = window.matchMedia && window.matchMedia('(max-device-width: 640px)').matches;
            if (isSmartPhone && this.loginUser.authority == 5 && this.loginUser.is_driver === 1) {
              this.$router.push({ path: 'BusDriverMenu' }); // バス運転手のその他職員がスマホでログインした際は送迎バスに遷移
            } else {
              this.$router.push({ name: "MainView" });
            }
          } else {
            this.errMsg = "";
          }
        }
      }
      this.loading = false;
    },
    onEnterDown() {
      this.submit();
    },
  },
  created() {
    // 保持しているsessionを削除する
    localStorage.removeItem('loginInfo');
  }
}

</script>

<style scoped>
.errMsg {
  padding-bottom: 2%;
  margin-left: 2%;
}
</style>
<style>
.SigninArea .v-card__text .v-input .v-input__control .v-input__slot {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
</style>
