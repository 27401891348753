import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import { isLoginCheck } from '@/mixins/index.js'

import DeliveryCreation from "../components/SegmentDelivery/DeliveryCreation.vue";
import SignIn from "../views/SignIn.vue";
import MainView from "../views/MainView.vue";
import StudentManage from "../components/StudentManage.vue";
import HomeView from "../components/HomeView.vue";
import StaffManage from "../components/StaffManage/StaffManage.vue";
import StaffDetail from "../components/StaffManage/StaffDetail/StaffDetail.vue";
import DeliveryPage from "../components/SegmentDelivery/SegmentDelivery.vue";
import ReplyMessageList from "../components/SegmentDelivery/ReplyMessageList.vue";
import ReadMessageList from "../components/SegmentDelivery/ReadMessageList.vue";
import BusManage from "../components/BusManage/BusManage.vue";
import BusDriverMenu from "../components/BusDriverMenu/BusDriverMenu.vue";
import SchoolSettings from "../components/Settings/SchoolSettings.vue";
import StaffIdRelation from "../views/StaffIdRelation.vue";
import StaffGroupSettings from "../components/Settings/StaffGroupSettings.vue";
import StaffDeliveryCreation from "../components/SegmentDelivery/StaffDeliveryCreation.vue";
import EnqueteList from "@/components/Enquete/EnqueteList.vue";
import EnqueteCreation from "@/components/Enquete/EnqueteCreation/index.vue";
import EnqueteDeliveryList from "@/components/Enquete/EnqueteDeliveryList.vue";
import EnqueteDeliveryCreation from "@/components/Enquete/EnqueteDeliveryCreation/EnqueteDeliveryCreation.vue";
import EnqueteDeliveryCreationCopy from "@/components/Enquete/EnqueteDeliveryCreation/EnqueteDeliveryCreation.vue";
import EnqueteAnswerList from "@/components/Enquete/EnqueteAnswerList.vue";
import SchoolLunchCalendar from "@/components/SchoolLunch/SchoolLunchCalendar/index.vue";
import SchoolLunchAllergenNotification from "@/components/SchoolLunch/SchoolLunchAllergenNotification/index.vue";
import CalendarManage from "../components/CalendarManage/CalendarManage.vue";
import AbsenceForm from "../components/AbsenceForm/AbsenceForm.vue";
import StudentGroupSettings from "../components/Settings/StudentGroupSettings.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/staffIdRelation",
    name: "StaffIdRelation",
    component: StaffIdRelation,
  },
  {
    path: "/",
    name: "MainView",
    component: MainView,
    props: true,
    children: [
      {
        path: "Home",
        name: "HomeView",
        component: HomeView,
      },
      {
        path: "StudentManage",
        name: "StudentManage",
        component: StudentManage,
      },
      {
        path: "DeliveryCreation",
        name: "DeliveryCreation",
        component: DeliveryCreation,
        props: true,
      },
      {
        path: "StaffDeliveryCreation",
        name: "StaffDeliveryCreation",
        component: StaffDeliveryCreation,
        props: true,
      },
      {
        path: "DeliveryList",
        name: "DeliveryList",
        component: DeliveryPage,
      },
      {
        path: "ReplyMessageList",
        name: "ReplyMessageList",
        component: ReplyMessageList,
        props: true,
      },
      {
        path: "ReadMessageList",
        name: "ReadMessageList",
        component: ReadMessageList,
        props: true,
      },
      {
        path: 'StaffManage',
        name: 'StaffManage',
        component: StaffManage,
      },
      {
        path: "StaffDetail",
        name: "StaffDetail",
        component: StaffDetail,
        props: true,
      },
      {
        path: "BusManage",
        name: "BusManage",
        component: BusManage,
        props: true,
      },
      {
        path: 'BusDriverMenu',
        name: 'BusDriverMenu',
        component: BusDriverMenu,
      },
      {
        path: 'SchoolSettings',
        name: 'SchoolSettings',
        component: SchoolSettings,
      },
      {
        path: 'StaffGroupSettings',
        name: 'StaffGroupSettings',
        component: StaffGroupSettings,
      },
      {
        path: 'EnqueteList',
        name: 'EnqueteList',
        component: EnqueteList,
      },
      {
        path: "EnqueteCreation/forms/:formType/new/template_:template",
        name: "EnqueteCreation",
        component: EnqueteCreation,
        props: true,
      },
      {
        path: "EnqueteDetail/forms/:formType/d/:surveyId",
        name: "EnqueteDetail",
        component: EnqueteCreation,
        props: true,
      },
      {
        path: "EnqueteDeliveryList",
        name: "EnqueteDeliveryList",
        component: EnqueteDeliveryList,
        props: true,
      },
      {
        path: "EnqueteDeliveryCreation",
        name: "EnqueteDeliveryCreation",
        component: EnqueteDeliveryCreation,
        props: true,
      },
      {
        path: "EnqueteDeliveryCreationCopy",
        name: "EnqueteDeliveryCreationCopy",
        component: EnqueteDeliveryCreationCopy,
        props: true,
      },
      {
        path: "EnqueteAnswerList",
        name: "EnqueteAnswerList",
        component: EnqueteAnswerList,
        props: true,
      },
      {
        path: "SchoolLunchCalendar",
        name: "SchoolLunchCalendar",
        component: SchoolLunchCalendar,
        props: true,
      },
      {
        path: "SchoolLunchAllergenNotification",
        name: "SchoolLunchAllergenNotification",
        component: SchoolLunchAllergenNotification,
        props: true,
      },
      {
      path: 'CalendarManage',
      name: 'CalendarManage',
      component: CalendarManage,
      },
      {
        path: 'AbsenceForm',
        name: 'AbsenceForm',
        component: AbsenceForm,
      },
      {
        path: 'StudentGroupSettings',
        name: 'StudentGroupSettings',
        component: StudentGroupSettings,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  // 画面遷移時にスクロール開始位置を先頭にする
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(savedPosition)
        })
      })
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {
  // LINE連携画面はいつでも自由に行ける
  if (to.name == "StaffIdRelation") {
    next();
  } else {
    if (to.name != "SignIn") {
      const isLocalState = isLoginCheck();
      if ((isLocalState == false || isLocalState == null || isLocalState == undefined) && to.name != "SignIn") {
        // next("/login");
        router.push({ path: 'login' }).catch(() => {});
      }
    }

    if (!store.state.isLogin) {
      // 未ログインならログイン画面へ
      if (to.name === "SignIn") {
        next();
      } else {
        router.push({ path: 'login' }).catch(() => {});
      }
    }
    // else if (store.state.isLogin && store.state.loginUser.is_driver === 1 && store.state.isSmartPhone) {
    //   // ログインしたユーザーが運転手かつスマホでログインしているなら送迎バスへ遷移
    //   if (to.name === "BusDriverMenu") {
    //     next();
    //   } else {
    //     router.push({ path: 'BusDriverMenu' }).catch(() => {});
    //   }
    // }
    else if ((to.name === "SignIn" || to.name === "MainView" || to.name === "HomeView")) {
      // 遷移させたくない画面ならホーム画面へリダイレクト
      // next("DeliveryList");
      router.push({ path: 'DeliveryList' });
    } else {
      next();
    }
  }
});
export default router;
