<template>
  <div>
    <v-dialog v-model="show" persistent scrollable max-width="800">
      <v-card max-width="100%">
        <v-system-bar color="primary" dark height="5"></v-system-bar>
        <v-toolbar flat>
          <v-toolbar-title>
            欠席連絡新規作成
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="show = false;">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text max-height="90vh">
          <v-card class="mt-5">
            <v-system-bar color="primary" dark height="3"></v-system-bar>
            <v-container>
              <div>
                <span class="font-weight-bold">{{absenceText}}する生徒</span>
                <span class="v-messages theme--light error--text">*</span>
              </div>
              <div>
                <span class="caption grey--text text--darken-3">{{absenceText}}する生徒を選択して下さい</span>
              </div>
              <v-row justify="start" class="mt-2">
                <v-col :cols="isSmartPhone ? 4 : 'auto'">
                  <v-subheader class="pr-0">クラス</v-subheader>
                </v-col>
                <v-col :cols="isSmartPhone ? 7 : 3" class="px-0">
                  <v-autocomplete 
                    :items="classroomAll"
                    :item-text="item => (item.grade_name + ' ' + item.class_name)"
                    item-value="classroom_id"
                    v-model="createFormInput.classroom"
                    @change="createFormInput.studentId = ''"
                    flat
                    outlined
                    dense
                    clearable
                    hide-details
                  >
                    <template v-slot:no-data>
                      <div class="px-4">データがありません</div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col :cols="isSmartPhone ? 4 : 'auto'">
                  <v-subheader class="pr-0">生徒氏名</v-subheader>
                </v-col>
                <v-col  :cols="isSmartPhone ? 7 : 3" class="px-0">
                  <v-autocomplete 
                    :disabled="!(createFormInput.classroom)"
                    :items="selectedClassStudent"
                    :item-text="item => (item.number + '番 : ' + item.last_name + item.first_name)"
                    item-value="student_id"
                    v-model="createFormInput.studentId"
                    flat
                    outlined
                    dense
                    clearable
                    hide-details
                  >
                    <template v-slot:no-data>
                      <div class="px-4">データがありません</div>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-card class="mt-5">
            <v-system-bar color="primary" dark height="3"></v-system-bar>
            <v-container>
              <div>
                <span class="font-weight-bold">連絡の種類</span>
                <span class="v-messages theme--light error--text">*</span>
              </div>
              <div>
                <span class="caption grey--text text--darken-3">連絡の種類を選択して下さい</span>
              </div>
              <v-row justify="start" class="mt-2">
                <v-col>
                  <v-radio-group v-model="createFormInput.formType" row hide-details class="py-2 mt-0">
                    <template>
                      <v-radio
                        v-for="formType in absenceFormTypeList" :key="formType.id"
                        :value="formType.id"
                        :label="formType.text"
                        class="px-4 pb-2"
                      ></v-radio>
                    </template>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-card class="mt-5">
            <v-system-bar color="primary" dark height="3"></v-system-bar>
            <v-container>
              <div>
                <span class="font-weight-bold">{{absenceText}}する日付</span>
                <span class="v-messages theme--light error--text">*</span>
              </div>
              <div>
                <span class="caption grey--text text--darken-3">{{absenceText}}する日付を入力して下さい</span>
              </div>
              <v-row justify="start" class="mt-2">
                <v-col>
                  <DatePicker v-model="createFormInput.absenceAt" class="custom-datepicker px-4 py-2"/>
                  <div v-if="(errMsgAbsenceAt !== '')" class="px-4 v-messages theme--light error--text">{{ errMsgAbsenceAt }}</div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-card v-if="formTypeIsLate" class="mt-5">
            <v-system-bar color="primary" dark height="3"></v-system-bar>
            <v-container>
              <div>
                <span class="font-weight-bold">登校時間</span>
                <span class="v-messages theme--light error--text">*</span>
              </div>
              <div>
                <span class="caption grey--text text--darken-3">何時ごろに登校されるか入力して下さい</span>
              </div>
              <v-row justify="start" class="mt-2">
                <v-col>
                  <v-row class="px-4">
                    <v-col cols="auto">
                      <v-select
                        v-model="createFormInput.arriveAt.hour"
                        :items="hours"
                        hide-details
                        class="no-space pt-0"
                      ></v-select>
                    </v-col>
                    <v-col cols="auto" class="px-0">
                      <v-subheader class="px-0">時</v-subheader>
                    </v-col>
                    <v-col cols="auto">
                      <v-select
                        v-model="createFormInput.arriveAt.minute"
                        :items="minutes"
                        hide-details
                        class="no-space pt-0"
                      ></v-select>
                    </v-col>
                    <v-col cols="auto" class="px-0">
                      <v-subheader class="px-0">分ごろ</v-subheader>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-card v-if="formTypeIsEarly" class="mt-5">
            <v-system-bar color="primary" dark height="3"></v-system-bar>
            <v-container>
              <div>
                <span class="font-weight-bold">早退時間</span>
                <span class="v-messages theme--light error--text">*</span>
              </div>
              <div>
                <span class="caption grey--text text--darken-3">何時ごろに早退されるか入力して下さい</span>
              </div>
              <v-row justify="start" class="mt-2">
                <v-col>
                  <v-row class="px-4">
                    <v-col cols="auto">
                      <v-select
                        v-model="createFormInput.arriveAt.hour"
                        :items="hours"
                        hide-details
                        class="no-space pt-0"
                      ></v-select>
                    </v-col>
                    <v-col cols="auto" class="px-0">
                      <v-subheader class="px-0">時</v-subheader>
                    </v-col>
                    <v-col cols="auto">
                      <v-select
                        v-model="createFormInput.arriveAt.minute"
                        :items="minutes"
                        hide-details
                        class="no-space pt-0"
                      ></v-select>
                    </v-col>
                    <v-col cols="auto" class="px-0">
                      <v-subheader class="px-0">分ごろ</v-subheader>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-card class="mt-5">
            <v-system-bar color="primary" dark height="3"></v-system-bar>
            <v-container>
              <div>
                <span class="font-weight-bold">{{absenceText}}する理由</span>
                <span class="v-messages theme--light error--text">*</span>
              </div>
              <div>
                <span class="caption grey--text text--darken-3">{{absenceText}}する理由を入力して下さい</span>
              </div>
              <v-row justify="start" class="mt-2">
                <v-col cols="auto">
                  <v-select
                    v-model="createFormInput.absenceReasonId"
                    :items="absenceReasonList"
                    item-text="text"
                    item-value="id"
                    hide-details
                    class="no-space pt-0 px-4 pb-2"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-card class="mt-5">
            <v-system-bar color="primary" dark height="3"></v-system-bar>
            <v-container>
              <div>
                <span class="font-weight-bold">備考</span>
                <span v-if="(createFormInput.absenceReasonId === 'other')" class="v-messages theme--light error--text">*</span>
              </div>
              <div>
                <span class="caption grey--text text--darken-3">連絡したい事項などあればご記入下さい</span>
              </div>
              <v-row justify="start" class="mt-2">
                <v-col>
                  <v-textarea
                    v-model="createFormInput.remarks"
                    rows="3"
                    :counter="256"
                    :error-messages="createFormInput.remarks?.length > 256 ? '備考は256文字以内で入力してください' : ''"
                    auto-grow
                    clearable
                    spellcheck="false"
                    outlined
                    
                    class="px-4 py-1"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn :disabled="formValidation || createFormInput.remarks?.length > 256" :loading="loading" color="primary" class="px-6" width="115" elevation="4" @click="createForm();">
                <v-icon left>mdi-content-save-outline</v-icon>
                保存
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn :loading="loading" color="grey lighten-2" class="px-6" width="115" elevation="4" @click="show = false;">
                <v-icon left>mdi-close-box-outline</v-icon>
                閉じる
              </v-btn>
            </v-col>
            <v-spacer v-if="isSmartPhone"></v-spacer>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" width="50%">
      <v-card color="#F3FAF5">
        <v-card-title></v-card-title>
        <v-card-text>
          {{ dialogMessage }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" outlined @click="dialog = false;">
            閉じる
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DatePicker from './DatePicker.vue';
import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import {
  GET_STUDENT_BY_CLASSROOM_ID,
  SEND_ABSENCE_FORM,
} from "@/store/action-types";
import {
  SET_SELECTED_CLASSROOM,
  SET_ABSENCE_FORM_TO_SEND,
} from "@/store/mutation-types";
import {
  ABSENCE_FORM_TYPE_LIST,
  ABSENCE_REASON_LIST,
} from "@/constants";

export default Vue.extend({
  name: 'AbsenceFormCreator',
  components: {
    DatePicker,
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      dialogMessage: '',
      absenceFormTypeList: ABSENCE_FORM_TYPE_LIST,
      hours: [6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,0,1,2,3,4,5],
      minutes: [0,15,30,45],
      absenceReasonList: ABSENCE_REASON_LIST,
      errMsgAbsenceAt: '',
      createFormInput: {
        classroom: '',
        studentId: '',
        formType: '',
        absenceAt: '',
        arriveAt: {
          hour: '',
          minute: ''
        },
        absenceReasonId: '',
        remarks: ''
      },
      absenceFormToSend: {
        school_id: '',
        target_year: '',
        parent_id: '',
        staff_id: '',
        class_id: '',
        grade_id: '',
        student_id: '',
        form_type: '',
        absence_at: '',
        arrive_at: '',
        absence_reason_id: '',
        remarks: ''
      },
      // 文言
      absenceText: '欠席',
    };
  },
  computed: {
    ...mapState({
      fiscalYear: state => state.fiscalYear,
      loginUser: state => state.loginUser,
      classroomAll: state => state.classroomAll,
      selectedClassStudent: state => state.selectedClassStudent,
      absenceFormToSendStore: state => state.absenceFormToSend,
      isSmartPhone: (state) => state.isSmartPhone,
    }),
    show: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    },
    formTypeIsLate() {
      return (this.createFormInput.formType === 'late');
    },
    formTypeIsEarly() {
      return (this.createFormInput.formType === 'early');
    },
    formValidation() {
      const isInvalidStudentId = this.isNullOrEmpty(this.createFormInput.studentId);
      const isInvalidFormType = this.isNullOrEmpty(this.createFormInput.formType);
      const isInvalidAbsenceAt = (this.isNullOrEmpty(this.createFormInput.absenceAt) || this.isPastDate(this.absenceFormToSend.absence_at));
      const isInvalidArriveAt = ((this.formTypeIsLate || this.formTypeIsEarly) && 
        (this.isNullOrEmpty(this.createFormInput.arriveAt.hour) || this.isNullOrEmpty(this.createFormInput.arriveAt.minute)));
      const isInvalidAbsenceReasonId = this.isNullOrEmpty(this.createFormInput.absenceReasonId);
      const isInvalidRemarks = (this.createFormInput.absenceReasonId === 'other') && (this.isNullOrEmpty(this.createFormInput.remarks));

      // console.log('isInvalidStudentId', isInvalidStudentId)
      // console.log('isInvalidFormType', isInvalidFormType)
      // console.log('isInvalidAbsenceAt', isInvalidAbsenceAt)
      // console.log('isInvalidArriveAt', isInvalidArriveAt)
      // console.log('isInvalidAbsenceReasonId', isInvalidAbsenceReasonId)
      // console.log('isInvalidRemarks', isInvalidRemarks)
      // console.log('this.formTypeIsLate', this.formTypeIsLate)
      // console.log('this.formTypeIsEarly', this.formTypeIsEarly)
      // console.log('this.isNullOrEmpty(this.createFormInput.arriveAt.hour)', this.isNullOrEmpty(this.createFormInput.arriveAt.hour))
      // console.log('this.isNullOrEmpty(this.createFormInput.arriveAt.minute)', this.isNullOrEmpty(this.createFormInput.arriveAt.minute))
      return (
        isInvalidStudentId ||
        isInvalidFormType ||
        isInvalidAbsenceAt ||
        isInvalidArriveAt ||
        isInvalidAbsenceReasonId ||
        isInvalidRemarks
      )
    },
  },
  methods: {
    ...mapActions({
      getStudentByClassId: GET_STUDENT_BY_CLASSROOM_ID,
      sendAbsenceForm: SEND_ABSENCE_FORM,
    }),
    ...mapMutations({
      setSelectedClassroom: SET_SELECTED_CLASSROOM,
      setAbsenceFormToSend: SET_ABSENCE_FORM_TO_SEND,
    }),
    initializeForm(){
      this.createFormInput.formType = 'absence';
      this.createFormInput.arriveAt.hour = 9;
      this.createFormInput.arriveAt.minute = 0;
      this.createFormInput.absenceReasonId = 'fever';
    },
    isNullOrEmpty(value) {
      return (value === null || value === undefined || value === '');
    },
    isPastDate(dateString) {
      const compareDate = new Date(dateString);
      compareDate.setHours(0, 0, 0, 0); // 時,分,秒,ミリ秒を全て0でセット
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); // 時,分,秒,ミリ秒を全て0でセット
      return (compareDate < currentDate);
    },
    checkInputAbsenceAt() {
      // 欠席日が昨日以前なら入力させない
      const isPastDate = this.isPastDate(this.absenceFormToSend.absence_at);
      if (isPastDate) {
        this.absenceFormToSend.absence_at = null;
        this.errMsgAbsenceAt = '※昨日以前の日付は入力できません。';
      } else {
        this.errMsgAbsenceAt = '';
      }
    },
    async createForm() {
      this.loading = true;
      this.absenceFormToSend.target_year = this.fiscalYear; // fiscalYearを用意
      this.absenceFormToSend.staff_id = this.loginUser.staff_id; // staff_idを用意

      this.setAbsenceFormToSend(this.absenceFormToSend);
      const response = await this.sendAbsenceForm();
      console.log('response', response);
      if (response && response.data && response.data.statusCode) {
        if (response.data.statusCode === 200) {
          this.show = false;
        // } else if (response.data.statusCode === 500) {
        //   if (response.data.body && (response.data.body === "duplicate-absence-at")) {
        //     this.dialogMessage = "既に同日に欠席連絡を送信しています。";
        //     this.dialog = true;
        //   }
        }
      }
      this.loading = false;
    },
  },
  watch: {
    'createFormInput.classroom': {
      handler() {
        const classroom = this.classroomAll.find((c) => c.classroom_id === this.createFormInput.classroom);
        this.setSelectedClassroom({
          class_id: classroom.class_id,
          grade_id: classroom.grade_id
        });
        this.getStudentByClassId();
      }
    },
    'createFormInput.studentId': {
      handler() {
        const student = this.selectedClassStudent.find((s) => s.student_id === this.createFormInput.studentId);
        this.absenceFormToSend.school_id = student.school_id;
        this.absenceFormToSend.class_id = student.classroom_id.slice(0, 2);
        this.absenceFormToSend.grade_id = student.classroom_id.slice(-2);
        this.absenceFormToSend.student_id = student.student_id;
      }
    },
    'createFormInput.formType': {
      handler() {
        this.absenceFormToSend.form_type = this.createFormInput.formType;
        if (this.createFormInput.formType == 'absence') {
          this.absenceText =  '欠席';
        } else if (this.createFormInput.formType == 'late') {
          this.absenceText =  '遅刻';
        } else if (this.createFormInput.formType == 'early') {
          this.absenceText =  '早退';
        }
      }
    },
    'createFormInput.absenceAt': {
      handler() {
        this.absenceFormToSend.absence_at = this.createFormInput.absenceAt;
        this.checkInputAbsenceAt();
      }
    },
    'createFormInput.arriveAt': {
      handler() {
        const hour = this.createFormInput.arriveAt.hour;
        const minute = this.createFormInput.arriveAt.minute;
        this.absenceFormToSend.arrive_at = hour + ":" + minute + ":00";
      },
      deep: true
    },
    'createFormInput.absenceReasonId': {
      handler() {
        this.absenceFormToSend.absence_reason_id = this.createFormInput.absenceReasonId;
      }
    },
    'createFormInput.remarks': {
      handler() {
        this.absenceFormToSend.remarks = this.createFormInput.remarks;
      }
    },
  },
  async created() {
    this.initializeForm();
  },
});
</script>

<style scoped>
.no-space ::v-deep .v-select__selections > input {
  width: 7px;
}
</style>