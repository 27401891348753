<template>
  <div :class="{ 'contentsArea': !isSmartPhone }" :style="{'margin-bottom': isSmartPhone ? '115px' : undefined}">
    <v-speed-dial v-if="isSmartPhone" v-model="isDialOpen" bottom right fixed transition="slide-y-reverse-transition" class="mb-3">
      <template v-slot:activator>
        <v-btn color="primary" dark fab>
          <v-icon>{{ isDialOpen ? 'mdi-close' : 'mdi-plus' }}</v-icon>
        </v-btn>
      </template>
      <template v-if="loginUser.authority != 5">
        <div class="d-flex justify-center align-center" style="margin-right: 125px;">
          <v-card width="125px" height="25px" color="primary" flat class="d-flex justify-center align-center">
            <v-subheader class="white--text pa-0">生徒向け新規配信</v-subheader>
          </v-card>
          <v-btn fab color="primary" @click="$router.push({ name: 'DeliveryCreation' })">
            <v-icon>mdi-email-plus-outline</v-icon>
          </v-btn>
        </div>
      </template>
      <template>
        <div class="d-flex justify-center align-center" style="margin-right: 125px;">
          <v-card width="125px" height="25px" color="blue darken-2" flat class="d-flex justify-center align-center">
            <v-subheader class="white--text pa-0">職員向け新規配信</v-subheader>
          </v-card>
          <v-btn fab color="blue darken-2" class="white--text" @click="$router.push({ name: 'StaffDeliveryCreation' })">
            <v-icon>mdi-account-tie</v-icon>
          </v-btn>
        </div>
      </template>
    </v-speed-dial>
    <v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="isSmartPhone" :loading="loading" color="blue darken-2" class="white--text"
          height="60px" width="140px" @click="$router.push({ name: 'StaffDeliveryCreation' })">
          <v-row dense>
            <v-col cols="12"><v-icon size="18px">mdi-account-tie</v-icon></v-col>
            <v-col cols="12">職員向け新規配信</v-col>
          </v-row>
        </v-btn>
        <v-btn v-if="loginUser.authority != 5 && isSmartPhone" :loading="loading" color="primary"
          height="60px" width="140px" @click="$router.push({ name: 'DeliveryCreation' })">
          <v-row dense>
            <v-col cols="12"><v-icon size="18px">mdi-email-plus-outline</v-icon></v-col>
            <v-col cols="12">生徒向け新規配信</v-col>
          </v-row>
        </v-btn>
        <v-spacer v-if="isSmartPhone"></v-spacer>
        <v-btn v-if="!isSmartPhone" :loading="loading" color="blue darken-2" class="white--text"
          @click="$router.push({ name: 'StaffDeliveryCreation' })">
          <v-icon left>mdi-account-tie</v-icon>
          職員向け新規配信
        </v-btn>
        <v-btn v-if="loginUser.authority != 5 && !isSmartPhone" :loading="loading" color="primary" @click="$router.push({ name: 'DeliveryCreation' })">
          <v-icon left>mdi-email-plus-outline</v-icon>
          生徒向け新規配信
        </v-btn>
      </v-card-actions>
      <!-- search box -->
      <v-btn text @click="showSearchBox = !showSearchBox">
        <v-icon left large color="primary">
          {{ showSearchBox ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
        配信メッセージ検索
      </v-btn>
      <v-expand-transition>
        <v-container v-if="showSearchBox" fluid>
          <v-form @submit.prevent ref="form" v-model="validSearchForm">
            <v-row>
              <v-col :cols="isSmartPhone ? 3 : 2" :class="{ 'mb-n3': isSmartPhone }">
                <v-subheader :class="{ 'justify-end': !isSmartPhone, 'px-0': isSmartPhone }"> 配信名 </v-subheader>
              </v-col>
              <v-col :cols="isSmartPhone ? 9 : undefined">
                <v-autocomplete :items="items" item-text="delivery_name" item-value="delivery_name"
                  v-model="deliveryNameFilterValue" flat outlined dense clearable hide-details
                  :inputmode="isSmartPhone ? 'none' : undefined">
                  <template v-slot:no-data>
                    <div class="px-4">データがありません</div>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="isSmartPhone ? 3 : 2" :class="{ 'mb-n3': isSmartPhone }">
                <v-subheader :class="{ 'justify-end': !isSmartPhone, 'px-0': isSmartPhone }"> 配信者 </v-subheader>
              </v-col>
              <v-col :cols="isSmartPhone ? 9 : undefined">
                <!-- TODO staff_idではなくstaff_nameになる -->
                <v-autocomplete :items="items" :item-text="item => item.last_name + ' ' + item.first_name"
                  item-value="staff_id" v-model="deliveryUserFilterValue" flat outlined dense clearable hide-details
                  :inputmode="isSmartPhone ? 'none' : undefined">
                  <template v-slot:no-data>
                    <div class="px-4">データがありません</div>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="isSmartPhone ? 3 : 2" :class="{ 'mb-n3': isSmartPhone }">
                <v-subheader :class="{ 'justify-end': !isSmartPhone, 'px-0': isSmartPhone }"> 配信期間 </v-subheader>
              </v-col>
              <v-col :cols="isSmartPhone ? 9 : 'auto'">
                <v-menu v-model="fromDateMenu" :close-on-content-click="true" transition="scale-transition" offset-y
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fromDateText" readonly outlined dense prepend-inner-icon="mdi-calendar-outline"
                      placeholder="YYYY-MM-DD" clearable hide-details v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="selectedFromDate" no-title color="primary" locale="jp-ja"
                    :day-format="(date) => new Date(date).getDate()">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="3" v-if="isSmartPhone">
              </v-col>
              <v-col :cols="isSmartPhone ? 9 : 'auto'" :class="{ 'mt-n4': isSmartPhone }">
                <v-subheader :class="{ 'justify-center my-n5': isSmartPhone }">~</v-subheader>
              </v-col>
              <v-col cols="3" v-if="isSmartPhone">
              </v-col>
              <v-col :cols="isSmartPhone ? 9 : 'auto'" :class="{ 'mt-n6': isSmartPhone }">
                <v-menu v-model="toDateMenu" :close-on-content-click="true" transition="scale-transition" offset-y
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="toDateText" readonly outlined dense prepend-inner-icon="mdi-calendar-outline"
                      placeholder="YYYY-MM-DD" clearable hide-details v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="selectedToDate" no-title color="primary" locale="jp-ja"
                    :day-format="(date) => new Date(date).getDate()">
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <!-- <v-row v-if="selectedCategory !== 'DRAFT'"> 後々増えるかもしれないので一旦コメント
              <v-col cols="2">
                <v-subheader class="justify-end">ステータス</v-subheader>
              </v-col>
              <v-col class="my-auto">
                <v-chip-group v-model="selectedState" mandatory active-class="primary--text">
                  <v-chip v-for="(state, index) of stateList[selectedCategory]" :key="index" :value="state" active small>
                    {{ convertStateToJapanese(state) }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row> -->
          </v-form>
        </v-container>
      </v-expand-transition>
      <v-divider></v-divider>
      <v-container class="my-4" fluid>
        <v-row no-gutters>
          <v-col cols="auto">
            <span class="display-1">{{ filteredItems.length }}</span>
            <span>件</span>
          </v-col>
          <v-divider vertical class="mx-4"></v-divider>
          <v-btn :loading="loading" color="primary" @click="reload">
            <v-icon :left="!isSmartPhone">mdi-reload</v-icon>
            <span v-show="!isSmartPhone">データ更新</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" @click="clearSearchItems" :disabled="!canClearSearch" color="gray">
            <v-icon :left="!isSmartPhone">mdi-filter-variant-remove</v-icon>
            <span v-show="!isSmartPhone">条件をクリアする</span>
          </v-btn>
        </v-row>
      </v-container>
      <div :class="{'smartphone-data-table': isSmartPhone}">
        <v-data-table
          :loading="loading"
          :loading-text="loadingText"
          :no-data-text="noDataText"
          :no-results-text="noResultText"
          class="table-cursor"
          :headers="headers"
          :items="items"
          single-select
          :items-per-page="perOnPage"
          :footer-props="{ 'items-per-page-options': [10, 20, 50, 100, -1], 'items-per-page-text': '行/ページ:' }"
          :page.sync="currentPage"
        >
          <template v-slot:[`item.delivery_id`]="{ item }">
            <div>
              <router-link v-if="item.delivery_id" :to="item.delivery_division == 'STAFF' ? { name: 'StaffDeliveryCreation', params: { item: item } } : { name: 'DeliveryCreation', params: { item: item } }"
                style="text-decoration: none">
                {{ item.delivery_id }}
              </router-link>
            </div>
          </template>
          <template v-slot:[`item.staff_id`]="{ item }">
            <div>
              {{ item.last_name + ' ' + item.first_name }}
            </div>
          </template>
          <template v-slot:[`item.delivery_schedule`]="{ item }">
            {{ item.delivery_schedule ? "予約配信" : "今すぐ配信" }}
          </template>
          <template v-slot:[`item.delivery_division`]="{ item }">
            <div :class="divisionColor(item.delivery_division)">
              {{ item.delivery_division ? item.delivery_division == "STAFF" ? "職員向け" : "生徒向け" : "ーー" }}
            </div>
          </template>
          <template v-slot:[`item.read_message_confirm`]="{ item }">
            <div>
              <v-btn v-if="item.delivery_division != 'STAFF' && item.delivery_status == 'DELIVERED'" class="green lighten-2 white--text" :small="isSmartPhone"
                @click="$router.push({ 
                    name: 'ReadMessageList', 
                    params: { delivery: item }, 
                    query: { deliveryId: item.delivery_id }, 
                })">
                確認
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.reply_message_confirm`]="{ item }">
            <div>
              <v-btn v-if="item.can_reply_flg" class="green lighten-2 white--text" :small="isSmartPhone"
                @click="$router.push({ 
                    name: 'ReplyMessageList', 
                    params: { delivery: item }, 
                    query: { deliveryId: item.delivery_id }, 
                })">
                確認
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.delivered_at`]="{ item }">
            {{ item.delivery_status == 'RESERVED' ? item.delivery_schedule ? formatToYYYYMMDDHHmmss(item.delivery_schedule) : "ーー" : item.delivered_at ?  formatToYYYYMMDDHHmmss(item.delivered_at) : "ーー" }}
          </template>
          <template v-slot:[`item.delivery_status`]="{ item }">
            <div :class="stateColor(item.delivery_status)">
              {{ item.delivery_status ? convertStateToJapanese(item.delivery_status) : "ーー" }}
            </div>
          </template>
          <template v-slot:[`footer.page-text`]="props">
            <div>全 {{ props.itemsLength }} 件中 {{ props.pageStart }} 件 〜 {{ props.pageStop }} 件を表示</div>
          </template>
          <template v-slot:no-data>
            データがありません
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog v-model="loading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          読み込んでいます
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import {
  mapActions,
  mapMutations,
  mapState,
} from "vuex";
import {
  LOADING_TEXT,
  NO_DATA_TEXT,
  NO_RESULT_TEXT,
} from "@/constants";
import {
  SAVE_DELIVERY_ITEM,
  GET_DELIVERY_ALL,
  GET_DELIVERY_ALL_LENGTH,
} from "@/store/action-types";
import moment from 'moment';
// import {
//   SET_SEARCH_YEAR
// } from "@/store/mutation-types";
export default Vue.extend({
  data() {
    return {
      noDataText: NO_DATA_TEXT,
      noResultText: NO_RESULT_TEXT,
      loading: false,
      loadingText: LOADING_TEXT,
      year: moment().subtract(3, 'months').year(),
      showSearchBox: true,
      categoryList: ["ALL", "RESERVED", "DRAFT"],
      categoryClicked: false,
      selectedCategoryToggleIndex: 0,
      selectedName: null,
      selectedCreatedBy: null,
      selectedFromDate: null,
      selectedToDate: null,
      selectedType: [],
      selectedState: null,
      stateList: {
        ALL: ["ALL", "PROCESSING", "DELIVERED", "ERROR", "IGNORED"],
        RESERVED: ["ALL", "PROCESSING", "DELIVERED", "ERROR", "IGNORED"],
        RECURRING: ["ALL", "PROCESSING", "DELIVERED", "ERROR", "IGNORED"], // ["ALL", "ERROR", "IGNORED"],
        DRAFT: [],
      },
      fromDateMenu: false,
      toDateMenu: false,
      canClearSearch: true, // false,
      items: [],
      currentPage: 1,
      perOnPage: 10,
      deliveryNameFilterValue: '',
      deliveryUserFilterValue: '',
      isDialOpen: false,
    };
  },
  computed: {
    ...mapState({
      deliveryItem: (state) => state.deliveryItem,
      deliveryHistoryItems: (state) => state.deliveryHistoryItems,
      loginUser: (state) => state.loginUser,
      fiscalYear: (state) => state.fiscalYear,
      isSmartPhone: (state) => state.isSmartPhone,
    }),
    headers() {
      return [
        { text: "配信ID", value: "delivery_id", width: "15%" },
        { text: "配信名", value: "delivery_name", filter: this.deliveryNameFilter, width: "26%" },
        { text: "配信者", value: "staff_id", filter: this.deliveryUserFilter, width: "13%" },
        { text: "ステータス", value: "delivery_status", width: "10%" },
        { text: "区分", value: "delivery_division", width: "10%" },
        // { text: "配信予定", value: "delivery_schedule" },
        { text: "既読状態", value: "read_message_confirm", width: "8%" },
        { text: "返信", value: "reply_message_confirm", width: "8%" },
        { text: "日時", value: "delivered_at", filter: this.deliveryDateFilter, width: "10%" },
      ];
    },
    filteredItems() {
      return this.items.filter(item => {
        return(
          this.deliveryNameFilter(item.delivery_name) &&
          this.deliveryUserFilter(item.staff_id) &&
          this.deliveryDateFilter(item.delivered_at)
        );
      });
    },
    selectedCategory: {
      get() {
        return this.categoryList[this.selectedCategoryToggleIndex];
      },
      set(value) {
        if (value && this.categoryList.indexOf(value) !== -1) {
          this.selectedCategoryToggleIndex = this.categoryList.indexOf(value);
        } else {
          this.selectedCategoryToggleIndex = 0;
        }
      },
    },
    fromDateText: {
      get() {
        return this.selectedFromDate;
      },
      set(value) {
        if (!value) {
          this.selectedFromDate = null;
        }
      },
    },
    toDateText: {
      get() {
        return this.selectedToDate;
      },
      set(value) {
        if (!value) {
          this.selectedToDate = null;
        }
      },
    },
    validSearchForm: {
      get() {
        return true;
      },
      set(value) { console.log('validSearchForm', value) },
    },
    canIncludeCreatedBy() {
      return !(this.selectedType.length === 1 && this.selectedType[0] === "EXTERNAL");
    },
  },
  watch: {
    distributionList(value) {
      this.items = value;
    },
    filteredDistributionList(value) {
      this.items = value;
    },
    selectedCategory(newValue, oldValue) {
      if (this.categoryClicked && oldValue !== newValue) {
        this.clearSearch();
        this.categoryClicked = false;
      }
      if (newValue === "RESERVED" || newValue === "RECURRING" || newValue === "DRAFT") {
        this.selectedType = ["MANUAL"];
      }
      this.updateDistributionListFilter(this.searchFilters);
    },
    selectedType(newValue, oldValue) {
      if (newValue && newValue.length === 1 && newValue[0] === "EXTERNAL") {
        this.selectedCreatedBy = "ーー";
      } else if (oldValue && oldValue.length === 1 && oldValue[0] === "EXTERNAL") {
        this.selectedCreatedBy = null;
      }
    },
    async fiscalYear() {
      await this.reload();
    },
  },
  methods: {
    clearSearchItems() {
      this.deliveryNameFilterValue = null;
      this.deliveryUserFilterValue = null;
      this.selectedToDate = null;
      this.selectedFromDate = null;
    },
    deliveryNameFilter(value) {
      if (!this.deliveryNameFilterValue) {
        return true
      }
      return value.toLowerCase().includes(this.deliveryNameFilterValue.toLowerCase());
    },
    deliveryUserFilter(value) {
      if (!this.deliveryUserFilterValue) {
        return true
      }
      return value == this.deliveryUserFilterValue;
    },
    deliveryDateFilter(value) {
      if (!this.selectedFromDate || !this.selectedToDate) {
        return true
      }
      return moment(moment(value).subtract(9, 'hours').format('YYYY-MM-DD')).isBetween(this.selectedFromDate, this.selectedToDate, undefined, '[]')
    },
    formatToYYYYMMDDHHmmss(value) {
      return moment(value).subtract(9, 'hours').format('YYYY-MM-DD HH:mm:ss')
    },
    ...mapActions({
      saveDeliveryItem: SAVE_DELIVERY_ITEM,
      getDeliveryAll: GET_DELIVERY_ALL,
      getDeliveryAllLength: GET_DELIVERY_ALL_LENGTH
    }),
    ...mapMutations({
      // setSearchYear: SET_SEARCH_YEAR,
    }),
    onDistributionDetail(item) {
      this.saveDeliveryItem(item)
      return {
        name: "DeliveryCreation",
      };
    },
    toDeliveryCliationEditMode(item) {
      this.$router.push({ name: 'DeliveryCreation', params: { item: item } })
    },
    divisionColor(v) {
      switch (v) {
        case "STAFF":
          return "blue--text";
        default:
          return "green--text";
      }
    },
    stateColor(value) {
      switch (value) {
        case "DELIVERED":
          return "green--text";
        case "ERROR":
          return "red--text";
        case "RESERVED":
          return "orange--text";
        default:
          return "black--text";
      }
    },
    convertStateToJapanese(value) {
      if (value === "ALL") {
        return "すべて";
      }
      if (value === "PROCESSING") {
        return "処理中";
      }
      if (value === "DELIVERED") {
        return "完了";
      }
      if (value === "ERROR") {
        return "エラー";
      }
      if (value === "IGNORED") {
        return "送信対象者無し";
      }
      if (value === "TALK") {
        return "ーー";
      }
      if (value === "RESERVED") {
        return "予約";
      }
      if (value === "DRAFT") {
        return "下書き";
      }
      //
      return value;
    },
    convertCategoryToJapanese(value) {
      if (value === "ALL") {
        return "すべて";
      }
      if (value === "RESERVED") {
        return "予約";
      }
      if (value === "DRAFT") {
        return "下書き";
      }
      return value;
    },
    convertTypeToJapanese(value) {
      if (value === "MANUAL") {
        return "手動配信";
      }
      if (value === "EXTERNAL") {
        return "外部配信";
      }
      if (value === "HOME") {
        return "ホームからの配信"
      }
      return value;
    },
    async reload() {
      this.loading = true;
      await this.getDeliveryAll();

      // ログイン中職員の、所属グループID一覧
      let staff_group_id_list = []
      for(let i=0; i < this.loginUser.staff_group.length; i++) {
        staff_group_id_list.push(this.loginUser.staff_group[i].staff_group_id)
      }
      if( this.loginUser.authority != 1 && this.loginUser.authority != 2){
        this.items = this.deliveryHistoryItems.filter(obj => 
        // 配信対象が生徒以外である（生徒配信の権限絞り込みはバックエンドで実施済み
        obj.delivery_division != 'STAFF'
        // 職員配信の場合
        || (obj.delivery_division == 'STAFF' 
        // 対象が職員グループかつ、ログインユーザーの職員グループが含まれていること
        && (obj.staff_delivery_target.segmentType == 'STAFF_GROUP' && ([...obj.staff_delivery_target.staff_group, ...staff_group_id_list].filter(item => obj.staff_delivery_target.staff_group.includes(item) && staff_group_id_list.includes(item)).length > 0))
          || obj.staff_delivery_target.segmentType == 'PERSONAL_STAFF' && this.loginUser.staff_id.includes(obj.staff_delivery_target.personal_staff)));
      } else {
        this.items = [...this.deliveryHistoryItems]
      }
      
      this.loading = false;
    },
    async getDeliveryList() {
      this.loading = true;
      await this.getDeliveryAll();
      
      // ログイン中職員の、所属グループID一覧
      let staff_group_id_list = []
      for(let i=0; i < this.loginUser.staff_group.length; i++) {
        staff_group_id_list.push(this.loginUser.staff_group[i].staff_group_id)
      }
      if( this.loginUser.authority != 1 && this.loginUser.authority != 2){
        this.items = this.deliveryHistoryItems.filter(obj => 
        // 配信対象が生徒以外である（生徒配信の権限絞り込みはバックエンドで実施済み
        obj.delivery_division != 'STAFF'
        // 職員配信の場合
        || (obj.delivery_division == 'STAFF' 
        // 対象が職員グループかつ、ログインユーザーの職員グループが含まれていること
        && (obj.staff_delivery_target.segmentType == 'STAFF_GROUP' && ([...obj.staff_delivery_target.staff_group, ...staff_group_id_list].filter(item => obj.staff_delivery_target.staff_group.includes(item) && staff_group_id_list.includes(item)).length > 0))
          || obj.staff_delivery_target.segmentType == 'PERSONAL_STAFF' && this.loginUser.staff_id.includes(obj.staff_delivery_target.personal_staff)));
      } else {
        this.items = [...this.deliveryHistoryItems]
      }
      this.loading = false;
    },
  },
  async created() {
    this.loading = true;
    // this.setSearchYear(this.year)
    await this.getDeliveryList()
    this.loading = false;
  },
});
</script>

<style scoped>
.smartphone-data-table ::v-deep .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row{
  height: unset;
  min-height: 30px;
}
</style>
