<template>
  <div class="item-flex">
    <v-row clas="color-icon-area" align="center">
      <v-col :cols="isSmartPhone ? 2 : 1" style="padding-top: 16px;">
        <v-icon @click="insertMarkdown('**', '**')" large>mdi-format-bold</v-icon>
      </v-col>
      <v-col :cols="isSmartPhone ? 2 : 1" style="padding-top: 16px;">
        <v-icon @click="insertMarkdown('*', '*')" large>mdi-format-italic</v-icon>
      </v-col>
      <v-col :cols="isSmartPhone ? 2 : 1">
        <v-img :src="fontSizeIcon" width="26" height="26" @click="showDialog('size')"></v-img>
      </v-col>
      <v-col :cols="isSmartPhone ? 2 : 1">
        <v-img :src="linkIcon" width="26" height="26" @click="showUrlDialog"></v-img>
      </v-col>
      <v-col :cols="isSmartPhone ? 2 : 1">
        <v-img :src="colorIcon" width="26" height="26" @click="showDialog('color')"></v-img>
      </v-col>
    </v-row>
    <v-row class="dialog-area">
      <div v-if="isShowDialog" class="overlay" @click="closeDialog"></div>
      <v-row v-if="isShowDialog" class="dialog" :style="position">
        <template v-if="iconListType == 'color'">
          <div class="color-icon-area">
            <v-icon class="custom-icon-red" @click="insertMarkdown('<red>', '</red>')" x-large>mdi-square</v-icon>
            <v-icon class="custom-icon-blue" @click="insertMarkdown('<blue>', '</blue>')" x-large>mdi-square</v-icon>
            <v-icon class="custom-icon-green" @click="insertMarkdown('<green>', '</green>')" x-large>mdi-square</v-icon>
            <v-icon class="custom-icon-pink" @click="insertMarkdown('<pink>', '</pink>')" x-large>mdi-square</v-icon>
          </div>
          
        </template>
        <template v-else-if="iconListType == 'size'">
            <v-slider
              v-model="slider"
              :max="4"
              step="1"
              ticks="always"
              thumb-label="always"
              :thumb-size="26"
              tick-size="6px"
              class="custom-slider"
              append-icon="mdi-plus"
              prepend-icon="mdi-minus"
              @click:append="plus"
              @click:prepend="minus"
              readonly
            >
              <template v-slot:thumb-label="{ value }">
                {{ sizeLabels[value] }}
              </template>
            </v-slider>
        </template>
      </v-row>
    </v-row>
    <v-row cols="12">
      <v-col cols="12">
        <v-textarea
          :color="textcolor"
          ref="textarea"
          placeholder="メッセージを入力して下さい"
          single-line
          outlined
          rows="4"
          hide-details="auto"
          v-model="markdown"
          :rules="rules"
          :disabled="!canEdit"
          @focus="isTextareaFocus = true"
        ></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import { cloneDeep } from "lodash";
import { unified } from 'unified';
import parse from 'remark-parse';
import { mapState, mapMutations } from "vuex";
import {
  SET_INPUT_URL,
} from "@/store/mutation-types";

export default Vue.extend({
  props: {
    value: [Object, String],
    rules: Array,
    index: Number,
    passedByParentsPositionInfo: Array,
    messages: Array,
    flexUseFlg: Boolean,
    textcolor: String,
    canEdit: Boolean,
  },
  data() {
    return {
      markdown: '',
      flexMessage: {},
      ast: {},
      templateFlexText: {
        type: "text",
        wrap: true,
        contents: []
      },
      templateFlexSpan: {
        type: "span",
        text: "",
      },
      contents: [],
      isBold: false,
      isItalic: false,
      isLink: false,
      actionLink: "",
      isShowDialog: false,
      iconListType: '',
      dialogLeft: 0,
      sizeLabels: ['最小', '小', '標準', '大', '最大'],
      slider: 2,
      fontSizeIcon: require('@/assets/icons/editor-font-size-icon.png'),
      linkIcon: require('@/assets/icons/editor-link-icon.png'),
      colorIcon: require('@/assets/icons/editor-color-icon.png'),
      top: 0,
      left: 0,
      startPos: 0,
      endPos: 0,
      isTextareaFocus: false,
    };
  },
  computed: {
    ...mapState({
      inputUrl: state => state.inputUrl,
      isSmartPhone: state => state.isSmartPhone,
    }),
    // CSS変数を定義して、CSS側に渡したいVueコンポーネントの値を指定する
    // ※今回はpropsで受け取った値
    position() {
      return {
        'top' : this.top + 'px',
        'left' : this.left + 'px',
      }
    },
  },
  watch: {
    markdown() {
      this.onChangeMarkdownMessage();
    },
    value(val) {
      if (!val) {
        this.markdown = "";
      }
    },
  },
  methods: {
    ...mapMutations({
      setInputUrl: SET_INPUT_URL
    }),
    plus() {
      this.slider = (this.slider + 1) || 4;
      this.insertSizeMarkdown(this.slider);
    },
    minus() {
      this.slider = (this.slider - 1) || 0;
      this.insertSizeMarkdown(this.slider);
    },
    onChangeMarkdownMessage() {
      this.convertToAst();
      this.convert();
      const sendData = {
        markdown: this.markdown,
        flexMessage: this.flexMessage,
      }
      this.$emit("onChangeMarkdownMessage", sendData, this.index);
    },
    insertMarkdown(openTag, closeTag) {
      if (this.canEdit) {
        const textarea = this.$refs.textarea.$refs.input;
        // 挿入時にフォーカスが先頭に戻ることを考慮する
        if (textarea.selectionStart == 0 && textarea.selectionEnd == 0 && this.isTextareaFocus) {
          textarea.selectionStart = 0;
          textarea.selectionEnd = 0;
          this.startPos = textarea.selectionStart;
          this.endPos = textarea.selectionEnd;
        } else if(textarea.selectionStart == 0 && textarea.selectionEnd == 0) {
          textarea.selectionStart = this.startPos;
          textarea.selectionEnd = this.endPos;
        } else if (this.isTextareaFocus) {
          this.startPos = textarea.selectionStart;
          this.endPos = textarea.selectionEnd;
        }

        const selectedText = textarea.value.substring(this.startPos, this.endPos);
        let replacement
        if (selectedText === "") {
          let sampleText = "";
          if (openTag == "**") {
            sampleText = "太文字";
          } else if (openTag == "*") {
            sampleText = "斜め文字";
          } else if (openTag == "[") {
            sampleText = "リンクテキスト";
          } else if (openTag == "<red>") {
            sampleText = "赤色";
          } else if (openTag == "<blue>") {
            sampleText = "青色";
          } else if (openTag == "<green>") {
            sampleText = "緑色";
          } else if (openTag == "<pink>") {
            sampleText = "ピンク色";
          }
          replacement = `${openTag}${sampleText}${closeTag}`;
          this.markdown = textarea.value.substring(0, this.startPos) + replacement + textarea.value.substring(this.endPos);
          // 文字列の開始位置に、開始タグ分の文字数を加える
          this.startPos = this.startPos + openTag.length;
          if (openTag == "[") {
            // 文字列の終了位置に、開始タグ分+サンプルテキストの文字数を加える
            this.endPos = openTag.length + sampleText.length + this.endPos;
          } else if (openTag == "<red>" || openTag == "<blue>" || openTag == "<green>" || openTag == "<pink>") {
            // 文字列の終了位置に、開始タグ分+サンプルテキストの文字数を加える
            this.endPos = openTag.length + sampleText.length + this.endPos;
          } else {
            this.endPos = this.endPos + sampleText.length + closeTag.length;
          }
        } else {
          // 装飾が既に適応されている場合は削除する
          if (openTag == "**") {
            // 開始タグを取得
            const startTag = textarea.value.substring(this.startPos - 2, this.startPos);
            // 終了タグを取得
            const endTag = textarea.value.substring(this.endPos, this.endPos + 2);
            // タグが両方存在するか
            if (startTag == "**" && endTag == "**") {
              // タグを削除して代入
              this.markdown = textarea.value.substring(0, this.startPos - 2) + selectedText + textarea.value.substring(this.endPos + 2);
              this.startPos = this.startPos - openTag.length;
              this.endPos = this.endPos - closeTag.length;
            } else {
              // タグを追加して代入
              replacement = `${openTag}${selectedText}${closeTag}`;

              this.markdown = textarea.value.substring(0, this.startPos) + replacement + textarea.value.substring(this.endPos);

              this.startPos = this.startPos + openTag.length;
              this.endPos = this.endPos + closeTag.length;
            }
          } else if (openTag == "*") {
            // ボールドとイタリックの開始タグ,終了タグを取得
            const italicStartTag = textarea.value.substring(this.startPos - 1, this.startPos);
            const italicEndTag = textarea.value.substring(this.endPos, this.endPos + 1);
            const boldStartTag = textarea.value.substring(this.startPos - 2, this.startPos);
            const boldEndTag = textarea.value.substring(this.endPos, this.endPos + 2);
            const boldItalicStartTag = textarea.value.substring(this.startPos - 3, this.startPos);
            const boldItalicEndTag = textarea.value.substring(this.endPos, this.endPos + 3);

            // ボールドかつ、イタリックのタグであるかチェック
            if (boldItalicStartTag == "***" && boldItalicEndTag == "***") {
              // タグを削除
              this.markdown = textarea.value.substring(0, this.startPos - 1) + selectedText + textarea.value.substring(this.endPos + 1);
              this.startPos = this.startPos - openTag.length;
              this.endPos = this.endPos - closeTag.length;
              textarea.selectionStart = this.startPos;
              textarea.selectionEnd = this.endPos;
              this.isTextareaFocus = false;
              return;
            }

            // ボールドのタグであるかチェック
            if (boldStartTag == "**" && boldEndTag == "**") {
              // タグを追加
              replacement = `${openTag}${selectedText}${closeTag}`;
              this.markdown = textarea.value.substring(0, this.startPos) + replacement + textarea.value.substring(this.endPos);

              this.startPos = this.startPos + openTag.length;
              this.endPos = this.endPos + closeTag.length;
              textarea.selectionStart = this.startPos;
              textarea.selectionEnd = this.endPos;
              this.isTextareaFocus = false;
              return;
            }
            // イタリックのタグであるかチェック
            if (italicStartTag == "*" && italicEndTag == "*") {
              // タグを削除
              this.markdown = textarea.value.substring(0, this.startPos - 1) + selectedText + textarea.value.substring(this.endPos + 1);
              this.startPos = this.startPos - openTag.length;
              this.endPos = this.endPos - closeTag.length;
              textarea.selectionStart = this.startPos;
              textarea.selectionEnd = this.endPos;
              this.isTextareaFocus = false;
              return;
            }

            // すべてに一致しない場合はタグを追加
            replacement = `${openTag}${selectedText}${closeTag}`;
            this.markdown = textarea.value.substring(0, this.startPos) + replacement + textarea.value.substring(this.endPos);

            this.startPos = this.startPos + openTag.length;
            this.endPos = this.endPos + closeTag.length;
          } else if (openTag == "[") {
            // 開始タグを取得
            const startTag = textarea.value.substring(this.startPos - 1, this.startPos);
            if (startTag == "[") {
              const str = textarea.value.substring(this.startPos - 1);
              const regex = /\[.*?\]\(.*?\)/g;
              const match = str.match(regex);
              if (match) {
                const capturedString = match[0];
                const urlRegex = /\((.*)\)/;
                const url = capturedString.match(urlRegex)[1];
                // ](任意のurl)の形式で削除するので、[ と ( と )と任意のurlの文字列分のindexを作成
                const index = 3 + url.length;

                // タグを削除して代入
                this.markdown = textarea.value.substring(0, this.startPos - 1) + selectedText + textarea.value.substring(this.endPos + index);
                this.startPos = this.startPos - openTag.length;
                this.endPos = this.endPos - openTag.length;
              } else {
                // タグを追加して代入
                // ここで呼ぶ
                replacement = `${openTag}${selectedText}${closeTag}`;

                this.markdown = textarea.value.substring(0, this.startPos) + replacement + textarea.value.substring(this.endPos);

                this.startPos = this.startPos + openTag.length;
                this.endPos = this.endPos + openTag.length;
              }
            } else {
              // タグを追加して代入
              replacement = `${openTag}${selectedText}${closeTag}`;

              this.markdown = textarea.value.substring(0, this.startPos) + replacement + textarea.value.substring(this.endPos);

              this.startPos = this.startPos + openTag.length;
              this.endPos = this.endPos + openTag.length;
            }
          } else if (openTag == "<red>") {
            // 赤、青、緑、ピンクのタグであるかチェック
            // 開始タグ,終了タグを取得
            const redStartTag = textarea.value.substring(this.startPos - openTag.length, this.startPos);
            const redEndTag = textarea.value.substring(this.endPos, this.endPos + closeTag.length);
            const blueStartTag = textarea.value.substring(this.startPos - 6, this.startPos);
            const blueEndTag = textarea.value.substring(this.endPos, this.endPos + 7);
            const greenStartTag = textarea.value.substring(this.startPos - 7, this.startPos);
            const greenEndTag = textarea.value.substring(this.endPos, this.endPos + 8);
            const pinkStartTag = textarea.value.substring(this.startPos - 6, this.startPos);
            const pinkEndTag = textarea.value.substring(this.endPos, this.endPos + 7);
            
            // 同じタグなのか、異なるタグなのか判定する
            if (openTag == redStartTag && closeTag == redEndTag) {
              // タグを削除して代入
              this.markdown = textarea.value.substring(0, this.startPos - openTag.length) + selectedText + textarea.value.substring(this.endPos + closeTag.length);
              // 代入後の開始と終了位置のポジションをセット
              // 開始位置から削除した開始タグ分を引く
              this.startPos = this.startPos - openTag.length;
              // 終了位置から削除した開始タグ分を引く
              this.endPos = this.endPos - openTag.length;
            } else if (blueStartTag == "<blue>" && blueEndTag == "</blue>" 
              || greenStartTag == "<green>" && greenEndTag == "</green>" 
              || pinkStartTag == "<pink>" && pinkEndTag == "</pink>")
            {
              let startTagOffset = 0;
              let endTagOffset = 0;
              if (blueStartTag == "<blue>") {
                startTagOffset = 6;
                endTagOffset = 7;
              } else if (greenStartTag == "<green>") {
                startTagOffset = 7;
                endTagOffset = 8;
              } else if (pinkStartTag == "<pink>") {
                startTagOffset = 6;
                endTagOffset = 7;
              }
              // 選択されたタグで置き換える
              // タグを削除して代入
              const tmpMarkdown = textarea.value.substring(0, this.startPos - startTagOffset) + selectedText + textarea.value.substring(this.endPos + endTagOffset);
              // 代入後の開始と終了位置のポジションをセット
              // 開始位置から削除した開始タグ分を引く
              this.startPos = this.startPos - startTagOffset;
              // 終了位置から削除した開始タグ分を引く
              this.endPos = this.endPos - startTagOffset;

              // タグを追加して代入
              replacement = `${openTag}${selectedText}${closeTag}`;
              this.markdown = tmpMarkdown.substring(0, this.startPos) + replacement + tmpMarkdown.substring(this.endPos);
              
              // 代入後の開始と終了位置のポジションをセット
              this.startPos = this.startPos + openTag.length;
              this.endPos = this.endPos + openTag.length;
            } else {
              // タグを追加して代入
              replacement = `${openTag}${selectedText}${closeTag}`;

              this.markdown = textarea.value.substring(0, this.startPos) + replacement + textarea.value.substring(this.endPos);
              // 代入後の開始と終了位置のポジションをセット
              this.startPos = this.startPos + openTag.length;
              this.endPos = this.endPos + openTag.length;
            }
          } else if (openTag == "<blue>") {
            // 赤、青、緑、ピンクのタグであるかチェック
            // 開始タグ,終了タグを取得
            const blueStartTag = textarea.value.substring(this.startPos - openTag.length, this.startPos);
            const blueEndTag = textarea.value.substring(this.endPos, this.endPos + closeTag.length);
            const redStartTag = textarea.value.substring(this.startPos - 5, this.startPos);
            const redEndTag = textarea.value.substring(this.endPos, this.endPos + 6);
            const greenStartTag = textarea.value.substring(this.startPos - 7, this.startPos);
            const greenEndTag = textarea.value.substring(this.endPos, this.endPos + 8);
            const pinkStartTag = textarea.value.substring(this.startPos - 6, this.startPos);
            const pinkEndTag = textarea.value.substring(this.endPos, this.endPos + 7);
            
            // 同じタグなのか、異なるタグなのか判定する
            if (openTag == blueStartTag && closeTag == blueEndTag) {
              // タグを削除して代入
              this.markdown = textarea.value.substring(0, this.startPos - openTag.length) + selectedText + textarea.value.substring(this.endPos + closeTag.length);
              // 代入後の開始と終了位置のポジションをセット
              // 開始位置から削除した開始タグ分を引く
              this.startPos = this.startPos - openTag.length;
              // 終了位置から削除した開始タグ分を引く
              this.endPos = this.endPos - openTag.length;
            } else if (redStartTag == "<red>" && redEndTag == "</red>" 
              || greenStartTag == "<green>" && greenEndTag == "</green>" 
              || pinkStartTag == "<pink>" && pinkEndTag == "</pink>")
            {
              let startTagOffset = 0;
              let endTagOffset = 0;
              if (redStartTag == "<red>") {
                startTagOffset = 5;
                endTagOffset = 6;
              } else if (greenStartTag == "<green>") {
                startTagOffset = 7;
                endTagOffset = 8;
              } else if (pinkStartTag == "<pink>") {
                startTagOffset = 6;
                endTagOffset = 7;
              }
              // 選択されたタグで置き換える
              // タグを削除して代入
              const tmpMarkdown = textarea.value.substring(0, this.startPos - startTagOffset) + selectedText + textarea.value.substring(this.endPos + endTagOffset);
              // 代入後の開始と終了位置のポジションをセット
              // 開始位置から削除した開始タグ分を引く
              this.startPos = this.startPos - startTagOffset;
              // 終了位置から削除した開始タグ分を引く
              this.endPos = this.endPos - startTagOffset;

              // タグを追加して代入
              replacement = `${openTag}${selectedText}${closeTag}`;
              this.markdown = tmpMarkdown.substring(0, this.startPos) + replacement + tmpMarkdown.substring(this.endPos);
              
              // 代入後の開始と終了位置のポジションをセット
              this.startPos = this.startPos + openTag.length;
              this.endPos = this.endPos + openTag.length;
            } else {
              // タグを追加して代入
              replacement = `${openTag}${selectedText}${closeTag}`;

              this.markdown = textarea.value.substring(0, this.startPos) + replacement + textarea.value.substring(this.endPos);
              // 代入後の開始と終了位置のポジションをセット
              this.startPos = this.startPos + openTag.length;
              this.endPos = this.endPos + openTag.length;
            }
          } else if (openTag == "<green>") {
            // 赤、青、緑、ピンクのタグであるかチェック
            // 開始タグ,終了タグを取得
            const greenStartTag = textarea.value.substring(this.startPos - openTag.length, this.startPos);
            const greenEndTag = textarea.value.substring(this.endPos, this.endPos + closeTag.length);
            const redStartTag = textarea.value.substring(this.startPos - 5, this.startPos);
            const redEndTag = textarea.value.substring(this.endPos, this.endPos + 6);
            const blueStartTag = textarea.value.substring(this.startPos - 6, this.startPos);
            const blueEndTag = textarea.value.substring(this.endPos, this.endPos + 7);
            
            const pinkStartTag = textarea.value.substring(this.startPos - 6, this.startPos);
            const pinkEndTag = textarea.value.substring(this.endPos, this.endPos + 7);
            
            // 同じタグなのか、異なるタグなのか判定する
            if (openTag == greenStartTag && closeTag == greenEndTag) {
              // タグを削除して代入
              this.markdown = textarea.value.substring(0, this.startPos - openTag.length) + selectedText + textarea.value.substring(this.endPos + closeTag.length);
              // 代入後の開始と終了位置のポジションをセット
              // 開始位置から削除した開始タグ分を引く
              this.startPos = this.startPos - openTag.length;
              // 終了位置から削除した開始タグ分を引く
              this.endPos = this.endPos - openTag.length;
            } else if (redStartTag == "<red>" && redEndTag == "</red>" 
              || blueStartTag == "<blue>" && blueEndTag == "</blue>" 
              || pinkStartTag == "<pink>" && pinkEndTag == "</pink>")
            {
              let startTagOffset = 0;
              let endTagOffset = 0;
              if (redStartTag == "<red>") {
                startTagOffset = 5;
                endTagOffset = 6;
              } else if (blueStartTag == "<blue>") {
                startTagOffset = 6;
                endTagOffset = 7;
              } else if (pinkStartTag == "<pink>") {
                startTagOffset = 6;
                endTagOffset = 7;
              }
              // 選択されたタグで置き換える
              // タグを削除して代入
              const tmpMarkdown = textarea.value.substring(0, this.startPos - startTagOffset) + selectedText + textarea.value.substring(this.endPos + endTagOffset);
              // 代入後の開始と終了位置のポジションをセット
              // 開始位置から削除した開始タグ分を引く
              this.startPos = this.startPos - startTagOffset;
              // 終了位置から削除した開始タグ分を引く
              this.endPos = this.endPos - startTagOffset;

              // タグを追加して代入
              replacement = `${openTag}${selectedText}${closeTag}`;
              this.markdown = tmpMarkdown.substring(0, this.startPos) + replacement + tmpMarkdown.substring(this.endPos);
              
              // 代入後の開始と終了位置のポジションをセット
              this.startPos = this.startPos + openTag.length;
              this.endPos = this.endPos + openTag.length;
            } else {
              // タグを追加して代入
              replacement = `${openTag}${selectedText}${closeTag}`;

              this.markdown = textarea.value.substring(0, this.startPos) + replacement + textarea.value.substring(this.endPos);
              // 代入後の開始と終了位置のポジションをセット
              this.startPos = this.startPos + openTag.length;
              this.endPos = this.endPos + openTag.length;
            }
          } else if (openTag == "<pink>") {
            // 赤、青、緑、ピンクのタグであるかチェック
            // 開始タグ,終了タグを取得
            const pinkStartTag = textarea.value.substring(this.startPos - openTag.length, this.startPos);
            const pinkEndTag = textarea.value.substring(this.endPos, this.endPos + closeTag.length);
            const redStartTag = textarea.value.substring(this.startPos - 5, this.startPos);
            const redEndTag = textarea.value.substring(this.endPos, this.endPos + 6);
            const blueStartTag = textarea.value.substring(this.startPos - 6, this.startPos);
            const blueEndTag = textarea.value.substring(this.endPos, this.endPos + 7);
            const greenStartTag = textarea.value.substring(this.startPos - 7, this.startPos);
            const greenEndTag = textarea.value.substring(this.endPos, this.endPos + 8);
            
            // 同じタグなのか、異なるタグなのか判定する
            if (openTag == pinkStartTag && closeTag == pinkEndTag) {
              // タグを削除して代入
              this.markdown = textarea.value.substring(0, this.startPos - openTag.length) + selectedText + textarea.value.substring(this.endPos + closeTag.length);
              // 代入後の開始と終了位置のポジションをセット
              // 開始位置から削除した開始タグ分を引く
              this.startPos = this.startPos - openTag.length;
              // 終了位置から削除した開始タグ分を引く
              this.endPos = this.endPos - openTag.length;
            } else if (redStartTag == "<red>" && redEndTag == "</red>" 
              || blueStartTag == "<blue>" && blueEndTag == "</blue>" 
              || greenStartTag == "<green>" && greenEndTag == "</green>")
            {
              let startTagOffset = 0;
              let endTagOffset = 0;
              if (redStartTag == "<red>") {
                startTagOffset = 5;
                endTagOffset = 6;
              } else if (blueStartTag == "<blue>") {
                startTagOffset = 6;
                endTagOffset = 7;
              } else if (greenStartTag == "<green>") {
                startTagOffset = 7;
                endTagOffset = 8;
              }
              // 選択されたタグで置き換える
              // タグを削除して代入
              const tmpMarkdown = textarea.value.substring(0, this.startPos - startTagOffset) + selectedText + textarea.value.substring(this.endPos + endTagOffset);
              // 代入後の開始と終了位置のポジションをセット
              // 開始位置から削除した開始タグ分を引く
              this.startPos = this.startPos - startTagOffset;
              // 終了位置から削除した開始タグ分を引く
              this.endPos = this.endPos - startTagOffset;

              // タグを追加して代入
              replacement = `${openTag}${selectedText}${closeTag}`;
              this.markdown = tmpMarkdown.substring(0, this.startPos) + replacement + tmpMarkdown.substring(this.endPos);
              
              // 代入後の開始と終了位置のポジションをセット
              this.startPos = this.startPos + openTag.length;
              this.endPos = this.endPos + openTag.length;
            } else {
              // タグを追加して代入
              replacement = `${openTag}${selectedText}${closeTag}`;

              this.markdown = textarea.value.substring(0, this.startPos) + replacement + textarea.value.substring(this.endPos);
              // 代入後の開始と終了位置のポジションをセット
              this.startPos = this.startPos + openTag.length;
              this.endPos = this.endPos + openTag.length;
            }
          }
        }
        textarea.selectionStart = this.startPos;
        textarea.selectionEnd = this.endPos;
        this.isTextareaFocus = false;
      }
    },
    showUrlDialog() {
      // テキストフィールドの値を取得
      const textarea = this.$refs.textarea.$refs.input;

      // 現在の文字位置から[]()の形式が存在するかチェック
      const str = textarea.value.substring(this.startPos - 1);
      const regex = /\[.*?\]\(.*?\)/g;
      const match = str.match(regex);
      // 存在している場合
      if (match) {
        this.insertMarkdown('[', '](' +  this.inputUrl + ')');
      } else {
        this.$dialog.show({
          title: "URLを入力してください。",
          text: "",
          type: "info",
          hideUrlInput: true,
          btnConfirmTitle: "完了",
          onConfirm: () => {
            this.insertMarkdown('[', '](' +  this.inputUrl + ')');
            // 入力されたurlを削除する
            this.setInputUrl("");
          },
        });
      }
    },
    insertSizeMarkdown(slider) {
      if (this.canEdit) {
        let openTag = "";
        let closeTag = "";
        // 数値を文字列に変換するロジックを追加してください
        if (slider === 0) {
          openTag = "<xs>";
          closeTag = "</xs>";
        } else if (slider === 1) {
          openTag = "<sm>";
          closeTag = "</sm>";
        } else if (slider === 2) {
          openTag = "<md>";
          closeTag = "</md>";
        } else if (slider === 3) {
          openTag = "<lg>";
          closeTag = "</lg>";
        } else if (slider === 4) {
          openTag = "<xl>";
          closeTag = "</xl>";
        }
        const textarea = this.$refs.textarea.$refs.input;
        // 挿入時にフォーカスが先頭に戻ることを考慮する
        if (textarea.selectionStart == 0 && textarea.selectionEnd == 0 && this.isTextareaFocus) {
          textarea.selectionStart = 0;
          textarea.selectionEnd = 0;
          this.startPos = textarea.selectionStart;
          this.endPos = textarea.selectionEnd;
        } else if(textarea.selectionStart == 0 && textarea.selectionEnd == 0) {
          textarea.selectionStart = this.startPos;
          textarea.selectionEnd = this.endPos;
        } else if (this.isTextareaFocus) {
          this.startPos = textarea.selectionStart;
          this.endPos = textarea.selectionEnd;
        }

        const selectedText = textarea.value.substring(this.startPos, this.endPos);
        
        let replacement
        if (selectedText === "") {
          let sampleText = "";
          if (openTag == "<xs>") {
            sampleText = "最小";
          } else if (openTag == "<sm>") {
            sampleText = "小";
          } else if (openTag == "<md>") {
            sampleText = "標準";
          } else if (openTag == "<lg>") {
            sampleText = "大";
          } else if (openTag == "<xl>") {
            sampleText = "最大";
          }

          replacement = `${openTag}${sampleText}${closeTag}`;
          this.markdown = textarea.value.substring(0, this.startPos) + replacement + textarea.value.substring(this.endPos);

          this.startPos = this.startPos + openTag.length;
          // 文字列の終了位置に、開始タグ分+サンプルテキストの文字数を加える
          this.endPos = openTag.length + sampleText.length + this.endPos;
        } else {
          // 装飾が既に適応されている場合は削除する
          if (openTag == "<xs>" || openTag == "<sm>" || openTag == "<md>" || openTag == "<lg>" || openTag == "<xl>") {
            // 開始タグを取得
            const startTag = textarea.value.substring(this.startPos - 4, this.startPos);
            // 終了タグを取得
            const endTag = textarea.value.substring(this.endPos, this.endPos + 5);
            // タグが両方存在するか
            if ((startTag == "<xs>" && endTag == "</xs>") 
              || (startTag == "<sm>" && endTag == "</sm>")
              || (startTag == "<md>" && endTag == "</md>")
              || (startTag == "<lg>" && endTag == "</lg>")
              || (startTag == "<xl>" && endTag == "</xl>")
            ) {
              if (slider === 0) {
                if (startTag != "<xs>") {
                  // スライダーで選択されたタグで置き換える
                  // タグを削除して代入
                  const tmpMarkdown = textarea.value.substring(0, this.startPos - 4) + selectedText + textarea.value.substring(this.endPos + 5);
                  // 代入後の開始と終了位置のポジションをセット
                  // 開始位置から削除した開始タグ分を引く
                  this.startPos = this.startPos - openTag.length;
                  // 終了位置から削除した開始タグ分を引く
                  this.endPos = this.endPos - openTag.length;

                  // タグを追加して代入
                  replacement = `${openTag}${selectedText}${closeTag}`;
                  
                  this.markdown = tmpMarkdown.substring(0, this.startPos) + replacement + tmpMarkdown.substring(this.endPos);
                  // 代入後の開始と終了位置のポジションをセット
                  this.startPos = this.startPos + openTag.length;
                  this.endPos = this.endPos + openTag.length;
                } else {
                  // タグを削除して代入
                  this.markdown = textarea.value.substring(0, this.startPos - 4) + selectedText + textarea.value.substring(this.endPos + 5);
                  // 代入後の開始と終了位置のポジションをセット
                  // 開始位置から削除した開始タグ分を引く
                  this.startPos = this.startPos - openTag.length;
                  // 終了位置から削除した開始タグ分を引く
                  this.endPos = this.endPos - openTag.length;
                  // スライダーを標準に戻す
                  this.slider = 2;
                }
              } else if (slider === 1) {
                if (startTag != "<sm>") {
                  // スライダーで選択されたタグで置き換える
                  // タグを削除して代入
                  const tmpMarkdown = textarea.value.substring(0, this.startPos - 4) + selectedText + textarea.value.substring(this.endPos + 5);
                  // 代入後の開始と終了位置のポジションをセット
                  // 開始位置から削除した開始タグ分を引く
                  this.startPos = this.startPos - openTag.length;
                  // 終了位置から削除した開始タグ分を引く
                  this.endPos = this.endPos - openTag.length;

                  // タグを追加して代入
                  replacement = `${openTag}${selectedText}${closeTag}`;
                  
                  this.markdown = tmpMarkdown.substring(0, this.startPos) + replacement + tmpMarkdown.substring(this.endPos);
                  // 代入後の開始と終了位置のポジションをセット
                  this.startPos = this.startPos + openTag.length;
                  this.endPos = this.endPos + openTag.length;
                } else {
                  // タグを削除して代入
                  this.markdown = textarea.value.substring(0, this.startPos - 4) + selectedText + textarea.value.substring(this.endPos + 5);
                  // 代入後の開始と終了位置のポジションをセット
                  // 開始位置から削除した開始タグ分を引く
                  this.startPos = this.startPos - openTag.length;
                  // 終了位置から削除した開始タグ分を引く
                  this.endPos = this.endPos - openTag.length;
                  // スライダーを標準に戻す
                  this.slider = 2;
                }
              } else if (slider === 2) {
                if (startTag != "<md>") {
                  // スライダーで選択されたタグで置き換える
                  // タグを削除して代入
                  const tmpMarkdown = textarea.value.substring(0, this.startPos - 4) + selectedText + textarea.value.substring(this.endPos + 5);
                  // 代入後の開始と終了位置のポジションをセット
                  // 開始位置から削除した開始タグ分を引く
                  this.startPos = this.startPos - openTag.length;
                  // 終了位置から削除した開始タグ分を引く
                  this.endPos = this.endPos - openTag.length;

                  // タグを追加して代入
                  replacement = `${openTag}${selectedText}${closeTag}`;
                  
                  this.markdown = tmpMarkdown.substring(0, this.startPos) + replacement + tmpMarkdown.substring(this.endPos);
                  // 代入後の開始と終了位置のポジションをセット
                  this.startPos = this.startPos + openTag.length;
                  this.endPos = this.endPos + openTag.length;
                } else {
                  // タグを削除して代入
                  this.markdown = textarea.value.substring(0, this.startPos - 4) + selectedText + textarea.value.substring(this.endPos + 5);
                  // 代入後の開始と終了位置のポジションをセット
                  // 開始位置から削除した開始タグ分を引く
                  this.startPos = this.startPos - openTag.length;
                  // 終了位置から削除した開始タグ分を引く
                  this.endPos = this.endPos - openTag.length;
                  // スライダーを標準に戻す
                  this.slider = 2;
                }
              } else if (slider === 3) {
                if (startTag != "<lg>") {
                  // スライダーで選択されたタグで置き換える
                  // タグを削除して代入
                  const tmpMarkdown = textarea.value.substring(0, this.startPos - 4) + selectedText + textarea.value.substring(this.endPos + 5);
                  
                  // 代入後の開始と終了位置のポジションをセット
                  // 開始位置から削除した開始タグ分を引く
                  this.startPos = this.startPos - openTag.length;
                  // 終了位置から削除した開始タグ分を引く
                  this.endPos = this.endPos - openTag.length;

                  // タグを追加して代入
                  replacement = `${openTag}${selectedText}${closeTag}`;
                  
                  this.markdown = tmpMarkdown.substring(0, this.startPos) + replacement + tmpMarkdown.substring(this.endPos);
                  // 代入後の開始と終了位置のポジションをセット
                  this.startPos = this.startPos + openTag.length;
                  this.endPos = this.endPos + openTag.length;
                } else {
                  // タグを削除して代入
                  this.markdown = textarea.value.substring(0, this.startPos - 4) + selectedText + textarea.value.substring(this.endPos + 5);
                  // 代入後の開始と終了位置のポジションをセット
                  // 開始位置から削除した開始タグ分を引く
                  this.startPos = this.startPos - openTag.length;
                  // 終了位置から削除した開始タグ分を引く
                  this.endPos = this.endPos - openTag.length;
                  // スライダーを標準に戻す
                  this.slider = 2;
                }
              } else if (slider === 4) {
                if (startTag != "<xl>") {
                  // スライダーで選択されたタグで置き換える
                  // タグを削除して代入
                  const tmpMarkdown = textarea.value.substring(0, this.startPos - 4) + selectedText + textarea.value.substring(this.endPos + 5);
                  // 代入後の開始と終了位置のポジションをセット
                  // 開始位置から削除した開始タグ分を引く
                  this.startPos = this.startPos - openTag.length;
                  // 終了位置から削除した開始タグ分を引く
                  this.endPos = this.endPos - openTag.length;
                  // タグを追加して代入
                  replacement = `${openTag}${selectedText}${closeTag}`;
                  
                  this.markdown = tmpMarkdown.substring(0, this.startPos) + replacement + tmpMarkdown.substring(this.endPos);
                  // 代入後の開始と終了位置のポジションをセット
                  this.startPos = this.startPos + openTag.length;
                  this.endPos = this.endPos + openTag.length;
                } else {
                  // タグを削除して代入
                  this.markdown = textarea.value.substring(0, this.startPos - 4) + selectedText + textarea.value.substring(this.endPos + 5);
                  // 代入後の開始と終了位置のポジションをセット
                  // 開始位置から削除した開始タグ分を引く
                  this.startPos = this.startPos - openTag.length;
                  // 終了位置から削除した開始タグ分を引く
                  this.endPos = this.endPos - openTag.length;
                  // スライダーを標準に戻す
                  this.slider = 2;
                }
              }
            } else {
              // タグを追加して代入
              replacement = `${openTag}${selectedText}${closeTag}`;

              this.markdown = textarea.value.substring(0, this.startPos) + replacement + textarea.value.substring(this.endPos);
              // 代入後の開始と終了位置のポジションをセット
              this.startPos = this.startPos + openTag.length;
              this.endPos = this.endPos + openTag.length;
            }
          } else {
            // タグを追加して代入
            replacement = `${openTag}${selectedText}${closeTag}`;
            this.markdown = textarea.value.substring(0, this.startPos) + replacement + textarea.value.substring(this.endPos);
            // 代入後の開始と終了位置のポジションをセット
            this.startPos = this.startPos + openTag.length;
            this.endPos = this.endPos + openTag.length;
          }
        }
        this.isTextareaFocus = false;
      }
    },
    convertToAst() {
      const processor = unified().use(parse);
      const mdast = processor.parse(this.markdown);
      this.ast = mdast;
    },
    convertToFlexMessage(ast) {
      if (ast.type === "root") {
        this.contents.push(cloneDeep(this.templateFlexText))
        return this.convertChildren(ast.children, "root");
      } else if (ast.type === "paragraph") {
        return this.convertChildren(ast.children, "paragraph");
      } else if (ast.type === "strong") {
        this.isBold = true;
        return this.convertChildren(ast.children, "strong");
      } else if (ast.type === "emphasis") {
        this.isItalic = true;
        return this.convertChildren(ast.children, "emphasis");
      } else if (ast.type === "link") {
        this.isLink = true;
        this.actionLink = ast.url;
        return this.convertChildren(ast.children, "link");
      } else if (ast.type === "html") {
        const contentIndex = ast.position.start.line - 1;

        if (typeof this.contents[contentIndex] === "undefined") {
          // contentIndexを指定して、存在しないcontentが存在する場合は、改行のみの行が存在するので、改行分のcontentも合わせて追加する
          for (let i = 0; i < contentIndex; i++) {
            if (typeof this.contents[i] === "undefined") {
              // 改行分のコンテンツを追加
              this.contents.push(cloneDeep(this.templateFlexText));
              // 改行コンテンツにspan要素を追加
              this.contents[i].contents.push({
                type: "span",
                text: " ", // 半角を設定する（LINE apiにてtextの空文字はエラーになるため）
              });
            }
          }
          // 対象のコンテンツを追加
          this.contents.push(cloneDeep(this.templateFlexText));
          let templateFlexSpan = {
            type: "span",
            text: ast.value,
          }
          this.contents[contentIndex].contents.push(templateFlexSpan);
        } else {
          let templateFlexSpan = {
            type: "span",
            text: ast.value,
          }
          this.contents[contentIndex].contents.push(templateFlexSpan);
        }
        return;
      } else if (ast.type === "text") {
        if (ast.value == "\n") {
          // 改行コードのみはテキスト要素を追加してリターン
          this.contents.push(cloneDeep(this.templateFlexText));
          return;
        } else {
          const value = ast.value;
          const contentIndex = ast.position.start.line - 1;
          // 改行が存在するテキストの場合は処理を分ける
          const linefeedPattern = this.checkLinefeedPattern(value)
          
          if(linefeedPattern === "intermediate") {
            // 改行コードを含む文字列の場合は改行コード分のテキスト要素を追加
            const linefeeds = this.getLinefeedFromText(value);
            linefeeds.forEach(() => {
              let flexText = cloneDeep(this.templateFlexText);
              flexText.contents.push({
                type: "span",
                text: " ", // 半角を設定する（LINE apiにてtextの空文字はエラーになるため）
              });
              this.contents.push(cloneDeep(flexText));
            });
            
            // 改行コードで文字列の配列を作成
            const values = value.split("\n");
            values.forEach((value, index) => {
              // 値が存在ある場合にspanを追加
              if (value) {
                // 値を挿入するtext要素はpositionから算出する
                const contentIndex = (ast.position.start.line - 1) + index;
                if (typeof this.contents[contentIndex] === "undefined") {
                  // 改行分のコンテンツを追加
                  this.contents.push(cloneDeep(this.templateFlexText));
                  // 改行コンテンツにspan要素を追加
                  this.contents[contentIndex].contents.push({
                    type: "span",
                    text: value,
                  });
                } else {
                  this.contents[contentIndex].contents.push({
                    type: "span",
                    text: value,
                  });
                }
              }
            });
            return;
          }

          let templateFlexSpan = {
            type: "span",
            text: "",
          }
          templateFlexSpan.text = value;
          if (this.isBold) {
            templateFlexSpan.weight = "bold";
          }
          if (this.isItalic) {
            templateFlexSpan.style = "italic";
          }
          const links = this.getLinksFromText(value);
          if (links.length != 0) {
            this.contents[contentIndex].action = {
              type: "uri",
              label: "action",
              uri: links[0],
            }
          }
          if (this.isLink) {
            if (typeof this.contents[contentIndex] === "undefined") {
              // contentIndexを指定して、存在しないcontentが存在する場合は、改行のみの行が存在するので、改行分のcontentも合わせて追加する
              for (let i = 0; i < contentIndex; i++) {
                if (typeof this.contents[i] === "undefined") {
                  // 改行分のコンテンツを追加
                  this.contents.push(cloneDeep(this.templateFlexText));
                  // 改行コンテンツにspan要素を追加
                  this.contents[i].contents.push({
                    type: "span",
                    text: " ", // 半角を設定する（LINE apiにてtextの空文字はエラーになるため）
                  });
                }
              }
              // 対象のコンテンツを追加
              this.contents.push(cloneDeep(this.templateFlexText));
              templateFlexSpan.color = "#0000FF";
              this.contents[contentIndex].action = {
                type: "uri",
                label: "action",
                uri: this.actionLink,
              }
            } else {
              templateFlexSpan.color = "#0000FF";
              this.contents[contentIndex].action = {
                type: "uri",
                label: "action",
                uri: this.actionLink,
              }
            }
          }

          if (typeof this.contents[contentIndex] === "undefined") {
            // contentIndexを指定して、存在しないcontentが存在する場合は、改行のみの行が存在するので、改行分のcontentも合わせて追加する
            for (let i = 0; i < contentIndex; i++) {
              if (typeof this.contents[i] === "undefined") {
                // 改行分のコンテンツを追加
                this.contents.push(cloneDeep(this.templateFlexText));
                // 改行コンテンツにspan要素を追加
                this.contents[i].contents.push({
                  type: "span",
                  text: " ", // 半角を設定する（LINE apiにてtextの空文字はエラーになるため）
                });
              }
            }
            // 対象のコンテンツを追加
            this.contents.push(cloneDeep(this.templateFlexText));
            this.contents[contentIndex].contents.push(templateFlexSpan);
          } else {
            this.contents[contentIndex].contents.push(templateFlexSpan);
          }
        }
        return;
      } else {
        return;
      }
    },
    convertChildren(children, type) {
      children.map(node => this.convertToFlexMessage(node));
      if (type === "strong") {
        this.isBold = false;
      } else if (type === "emphasis") {
        this.isItalic = false;
      } else if (type === "link") {
        this.isLink = false;
        this.actionLink = "";
      }
    },
    convert() {
      this.convertToFlexMessage(this.ast);
      this.htmlDecoration();
      this.flexMessage = {
        type: "bubble",
        body: {
          type: "box",
          layout: "vertical",
          contents: this.contents
        }
      }
      // 変換後はデータを初期化する
      this.templateFlexText = {
        type: "text",
        wrap: true,
        contents: []
      }
      this.templateFlexSpan = {
        type: "span",
        text: "",
      }
      this.ast = {};
      this.contents = [];
    },
    checkLinefeedPattern(str) {
      if (str.includes('\n')) {
        return "intermediate";
      } else {
        return "none";
      }
    },
    getLinefeedFromText(str) {
      const regex = /\n/g;
      const matches = str.match(regex);
      return matches || [];
    },
    getLinksFromText(text) {
      const regex = /(https?:\/\/[^\s<]+)/g;
      const matches = text.match(regex);
      return matches || [];
    },
    htmlDecoration() {
      let htmlTags = [];
      // 開始タグ終了タグのセットを保持
      this.contents.map((content, contentIndex) => {
        content.contents.map((span, sapnIndex) => {
          if (this.isTargetHtmlTag(span.text, "start")) {
            let htmlTag = {
              line: contentIndex,
              start: sapnIndex,
              value: span.text,
            };
            htmlTags.push(htmlTag);
          } else if (this.isTargetHtmlTag(span.text, "end")) {
            if (htmlTags.length) {
              let isExist = false;
              htmlTags.forEach(tag => {
                // startに数値が入ってる && endが設定されていない && 同じ行 && 対象の終了タグ
                if (
                  typeof tag.start != "undefined" && 
                  typeof tag.end === "undefined" && 
                  tag.line == contentIndex && 
                  this.isTargetStartHtmlTag(tag.value, span.text)
                ) {
                  isExist = true;
                  tag.end = sapnIndex;
                  return;
                }
              });
              // 挿入するタグが見つからなかった場合は、push
              if (!isExist) {
                let htmlTag = {
                  line: contentIndex,
                  end: sapnIndex,
                  value: span.text,
                };
                htmlTags.push(htmlTag);
              }
            } else {
              // 配列が空の場合はtagをpush
              let htmlTag = {
                line: contentIndex,
                end: sapnIndex,
                value: span.text,
              };
              htmlTags.push(htmlTag);
            }
          }
        })
        
        // 開始indexより大きいかつ、終了indexより小さいspanに装飾を追加する
        htmlTags.forEach(tag => {
          if (typeof tag.start != "undefined"  && typeof tag.end != "undefined") {
            this.contents[tag.line].contents = this.contents[tag.line].contents.map((span, spanIndex) => {
              let decorationSpan = span;
              if (spanIndex > tag.start && spanIndex < tag.end) {
                decorationSpan = this.decoration(tag.value, span);
              }
              return decorationSpan;
            });
          }
        });
        // 開始タグと終了タグが正しく設定されているspanはJSONから削除する
        htmlTags.forEach(tag => {
          if (typeof tag.start != "undefined"  && typeof tag.end != "undefined") {
            // 指定した要素を削除する
            delete this.contents[tag.line].contents[tag.start];
            delete this.contents[tag.line].contents[tag.end];
          }
        });
      });
      // 要素削除後に残ってしまうnullを省く
      this.contents.map((content, index) => {
        this.contents[index].contents = content.contents.filter(Boolean);
      })
    },
    /**
     * 
     * 対象の色、サイズを追加する場合は以下のロジックを修正する
     */
    isTargetHtmlTag(htmlTag, type) {
      if (type == "start") {
        // 対象の開始タグであるか判定
        if (htmlTag === "<red>") {
          return true;
        } else if (htmlTag === "<blue>") {
          return true;
        } else if (htmlTag === "<green>") {
          return true;
        } else if (htmlTag === "<pink>") {
          return true;
        } else if (htmlTag === "<xs>") {
          return true;
        } else if (htmlTag === "<sm>") {
          return true;
        } else if (htmlTag === "<md>") {
          return true;
        } else if (htmlTag === "<lg>") {
          return true;
        } else if (htmlTag === "<xl>") {
          return true;
        }
      } else {
        // 対象の終了タグであるか判定
        if (htmlTag === "</red>") {
          return true;
        } else if (htmlTag === "</blue>") {
          return true;
        } else if (htmlTag === "</green>") {
          return true;
        } else if (htmlTag === "</pink>") {
          return true;
        } else if (htmlTag === "</xs>") {
          return true;
        } else if (htmlTag === "</sm>") {
          return true;
        } else if (htmlTag === "</md>") {
          return true;
        } else if (htmlTag === "</lg>") {
          return true;
        } else if (htmlTag === "</xl>") {
          return true;
        }
      }
      return false;
    },
    isTargetStartHtmlTag(startHtmlTag, endHtmlTag) {
      if (startHtmlTag === "<red>") {
        if (endHtmlTag === "</red>") {
          return true
        }
      } else if (startHtmlTag === "<blue>") {
        if (endHtmlTag === "</blue>") {
          return true
        }
      } else if (startHtmlTag === "<green>") {
        if (endHtmlTag === "</green>") {
          return true
        }
      } else if (startHtmlTag === "<pink>") {
        if (endHtmlTag === "</pink>") {
          return true
        }
      } else if (startHtmlTag === "<xs>") {
        if (endHtmlTag === "</xs>") {
          return true
        }
      }else if (startHtmlTag === "<sm>") {
        if (endHtmlTag === "</sm>") {
          return true
        }
      } else if (startHtmlTag === "<md>") {
        if (endHtmlTag === "</md>") {
          return true
        }
      } else if (startHtmlTag === "<lg>") {
        if (endHtmlTag === "</lg>") {
          return true
        }
      } else if (startHtmlTag === "<xl>") {
        if (endHtmlTag === "</xl>") {
          return true
        }
      }
      return false;
    },
    decoration(htmlTag, span) {
      if (htmlTag === "<red>") {
        span.color = "#F6302F";
      } else if (htmlTag === "<blue>") {
        span.color = "#3E15FF";
      } else if (htmlTag === "<green>") {
        span.color = "#168200";
      } else if (htmlTag === "<pink>") {
        span.color = "#FFC0CB";
      } else if (htmlTag === "<xs>") {
        span.size = "xs";
      } else if (htmlTag === "<sm>") {
        span.size = "sm";
      } else if (htmlTag === "<md>") {
        span.size = "md";
      } else if (htmlTag === "<lg>") {
        span.size = "lg";
      } else if (htmlTag === "<xl>") {
        span.size = "xl";
      }
      return span;
    },
    showDialog(type) {
      this.isShowDialog = true;
      if (type == "color") {
        this.top = -100;
        this.left = 276;
      } else {
        this.top = -124;
        this.left = 147;
      }
      this.iconListType = type;
      // this.setDialogLeft();
    },
    closeDialog() {
      this.isShowDialog = false;
    },
    // setDialogLeft() {
    //   const buttonRect = this.$refs.button.getBoundingClientRect();
    //   const buttonOffsetLeft = window.pageXOffset + buttonRect.left; // ボタンの左側の位置
    //   this.dialogLeft = buttonOffsetLeft;
    // }
  },
  created() {
    this.markdown = this.value;
  }
});
</script>
<style>
/* .dialog-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.dialog-area {
  position: relative;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1;
}
.dialog {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3%;
  z-index: 2;
}
/* .icon-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
} */
.custom-icon-red {
  color: #F6302F !important; /* カラーコードを指定 */
}
.custom-icon-blue {
  color: #3E15FF !important; /* カラーコードを指定 */
}
.custom-icon-green {
  color: #00FF00 !important; /* カラーコードを指定 */
}
.custom-icon-pink {
  color: #FFC0CB !important; /* カラーコードを指定 */
}
/**
v-iconの色を変更
 */
.item-flex .v-icon {
  color: #4B4B4B;
}
.icon {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.color-icon-area {
  margin: 0.5rem;
}
/**
v-sliderの見た目をCSSで変更
 */
.custom-slider {
  width: 200px;
  margin-top: 1.8rem;
}
.custom-slider .v-slider__tick {
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #000;
}
.custom-slider .v-slider__tick.v-slider__tick--filled {
  background-color: #4caf50; /* 選択されている時の色 */
  border: none;
}
.custom-slider .v-slider__track-container .v-slider__track-background {
  background-color: black !important;
}
</style>