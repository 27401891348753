<template>
  <div>
    <v-app-bar style="z-index: 10;" app color=green dark>
      <v-row v-if="isSmartPhone">
        <v-col cols="3" lg="2" class="fiscal_year">
          <v-row>
            <v-col class="pa-0">
              <div>{{ currentSchool.school_name + dispEnvName }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-0">
              <div>対象年度: {{ loginUser.authority == 1 ? fiscalYear : loginUser.fiscal_year }}年度</div>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-spacer></v-spacer>
        </v-col>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer">
          <v-icon>{{ drawer ? 'mdi-close' : 'mdi-menu' }}</v-icon>
        </v-app-bar-nav-icon>
      </v-row>
      <v-row v-else>
        <v-col class="fiscal_year">
          <v-row class="pt-2">
            <v-col class="pa-0">
              <div>{{ currentSchool.school_name + dispEnvName }}</div>
            </v-col>
          </v-row>
          <v-row v-if="loginUser.authority === 1">
            <v-col cols="auto" class="pa-0">
              <div class="mr-3 pt-1 fiscal_year_title">
                対象年度:
              </div>
            </v-col>
            <v-col class="pa-0">
              <v-select
                v-model="selectedYear"
                :items="this.fiscalYearList"
                :item-text="item => item.category_text"
                :item-value="item => item.category_id"
                dense
                density="compact"
                hide-details="false"
                class="fiscal_year_selectbox text-caption"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col class="pa-0">
              <div>対象年度: {{ loginUser.fiscal_year }}年度</div>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-spacer></v-spacer>
        </v-col>
        <v-col cols="auto">
          <v-menu offset-y transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="login-status py-5">
                {{ convertAuthorityToJapanese(loginUser) }}<br>
                {{ loginUser.staff_id + ' ' + loginUser.last_name + ' ' + loginUser.first_name }}
              </v-btn>
            </template>

            <div class="setting-btn-area">
              <v-btn @click="isQrDialog = true;" elevation="0" class="setting-btn">
                LINE連携
              </v-btn>
              <v-btn href="https://tc-school-app.notion.site/1c1eedd3cf9c4ca1ab4c692e5345c606?pvs=4" target="_blank" elevation="0" class="setting-btn">
                マニュアル
              </v-btn>
              <v-btn href="https://tc-school-app.notion.site/be1e288a1bfe46d986f593b1f55e5b4a?pvs=4" target="_blank" elevation="0" class="setting-btn">
                バージョンアップ情報
              </v-btn>
              <v-btn @click="logout" elevation="0" class="setting-btn">
                <v-icon left>mdi-exit-to-app</v-icon>ログアウト
              </v-btn>
            </div>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-dialog v-if="isQrDialog" v-model="isQrDialog">
      <v-card style="height: 60%;">
        <v-card-title>
          LINE連携
        </v-card-title>
        <v-container style="height: 60%;">
          <v-row style="height: 600px;">
            <v-col cols="4">
              <v-card style="height: 100%;">
                <v-container fill-height>
                  <v-col cols="12">
                    <VueQrcode
                      style="position: absolute; left: 50%; margin-right: -50%; top: 50%; transform: translate(-50%, -50%);"
                      :value="qrCodeDefault + 'staffId=' + loginUser.staff_id + '&pass=' + loginUser.password"
                      :options="{ width: 250 }"
                    />
                  </v-col>
                </v-container>
              </v-card>
            </v-col>
            <v-col style="height: 100%;">
              <v-card style="height: 100%;" class="overflow-y-auto">
                <v-card-title>
                  QRコードの読み取りについて
                </v-card-title>

                <v-col>
                  <v-row>
                    <v-col>
                      1.[ホーム]＞[友だち追加]＞[QRコード]をタップします。
                    </v-col>
                    <v-col>
                      <img style="width: 300px; outline: solid;" cover src="@/assets/img/lineCodeReader-1.png" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row>
                    <v-col>
                      2.QRコードリーダーを開き、連携用QRコードをスキャンしてください。
                    </v-col>
                    <v-col>
                      <img style="width: 300px; outline: solid;" cover src="@/assets/img/lineCodeReader-2.png" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-card color="green lighten-4">
                    <v-card-title style="color: green;">QRコードリーダーの簡単起動</v-card-title>
                    <v-row>
                      <v-col>
                        <v-card-text>
                          ホームタブ／トークタブ／ニュースタブの検索窓にある[QRコード]マークや、
                          ホーム画面のLINEアイコンを長押しした際に出てくるメニューから簡単に起動することが出来ます。
                        </v-card-text>
                      </v-col>
                      <v-col>
                        <img style="width: 300px; outline: solid;" cover src="@/assets/img/lineCodeReader-3.png" />
                        <img style="width: 300px; outline: solid;" cover src="@/assets/img/lineCodeReader-4.png" />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="isQrDialog = false">閉じる</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- スマホ用メニュー -->
    <v-navigation-drawer v-model="drawer" style="position:fixed;" bottom absolute temporary>
      <v-container class="pt-0 px-0">
        <v-card class="green white--text rounded-0 pa-1">
          {{ convertAuthorityToJapanese(loginUser) }}
          <br>{{ loginUser.staff_id + ' ' + loginUser.last_name + ' '+ loginUser.first_name }}
        </v-card>
      </v-container>
      <SideMenu v-show="isSmartPhone"/>
      <v-divider class="mb-2"></v-divider>
      <div class="pa-2 pt-3">
        <v-btn block href="https://tc-school-app.notion.site/1c1eedd3cf9c4ca1ab4c692e5345c606?pvs=4" target="_blank" color="primary" class="white-text">
          マニュアル
        </v-btn>
      </div>
      <div class="pa-2">
        <v-btn block href="https://tc-school-app.notion.site/be1e288a1bfe46d986f593b1f55e5b4a?pvs=4" target="_blank" color="primary" class="white-text">
          バージョンアップ情報
        </v-btn>
      </div>
      <div class="pa-2">
        <v-btn block @click="logout" color="primary" class="white-text">
          <v-icon left>mdi-exit-to-app</v-icon>ログアウト
        </v-btn>
      </div>
    </v-navigation-drawer>

    <SideMenu v-show="!isSmartPhone" style="width: 200px" />

    <v-main style="padding-top:0; padding-left: none;" elevation="0">
      <router-view />
    </v-main>
    <v-dialog persistent v-if="signOutDialog" v-model="signOutDialog" width="600px">
      <v-card>
        <v-card-text class="pt-6">
          ログイン情報に変更がありました。<br>
          お手数ですが、再度ログインしてください。
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="primary" @click="logout()">ログイン画面へ</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import VueQrcode from "@chenfengyuan/vue-qrcode";
Vue.component(VueQrcode.name, VueQrcode);
import SideMenu from '@/components/SideMenu.vue';
import { mapState, mapMutations, mapActions } from "vuex";
import {
  GET_CURRENT_SCHOOL,
} from "@/store/action-types"
import {
  SET_SEARCH_YEAR,
} from "@/store/mutation-types"
import {
  AUTHORITIES
} from "@/constants";

export default {
  data() {
    return {
      signOutDialog: false,
      group: null,
      drawer: false,
      width: window.innerWidth,
      authorities: AUTHORITIES,
      selectedYear: null,
      dispEnvName: process.env.VUE_APP_DISP_ENV_NAME,
      isQrDialog: false,
      qrCodeDefault: 'https://liff.line.me/' + process.env.VUE_APP_STAFF_LOGIN_LIFF_ID + '?',
    }
  },
  components: {
    SideMenu,
    VueQrcode,
  },
  computed: {
    ...mapState({
      currentSchool: (state) => state.currentSchool,
      isSmartPhone: (state) => state.isSmartPhone,
      loginUser: (state) => state.loginUser,
      needCreateSchool: (state) => state.setup.needCreateSchool,
      needCreateStaff: (state) => state.setup.needCreateStaff,
      needCreateStudent: (state) => state.setup.needCreateStudent,
      fiscalYear: (state) => state.fiscalYear,
    }),
    fiscalYearList() {
      let yearList = [];
      if (this.currentSchool.setting) {
        const currentYear = moment().subtract(3, 'months').year()
        const schoolCreatedAt = this.currentSchool.setting.created_at.replaceAll('/', '-');
        const startYear = moment(schoolCreatedAt).subtract(3, 'months').year();
        const endYear = currentYear + 1;
        for (let year = startYear; year <= endYear; year++) {
          let categoryText = '';
          if (year === currentYear) {
            categoryText = `当年度(${year}年度)`;
          } else if (year === currentYear + 1) {
            categoryText = `次年度(${year}年度)`;
          } else {
            categoryText = `過去年度(${year}年度)`;
          }
          yearList.push({
            category_text: categoryText,
            category_id: year
          });
        }
      }
      return yearList
    }
  },
  methods: {
    ...mapActions({
      getCurrentSchool: GET_CURRENT_SCHOOL,
    }),
    ...mapMutations({
      setFiscalYear: SET_SEARCH_YEAR,
    }),
    convertAuthorityToJapanese(staff) {
      // 権限を検索
      const authority_id = staff.authority;
      const authority = this.authorities.find((a) => a.authority_id === authority_id);
      console.log('authority', authority)

      let displayString = "";
      if (authority) {
        const authorityName = authority.authority_name;
        if (authority.authority_id === 3) {
          const manageGrade = staff.managed_grade_info.school_grade;
          displayString = manageGrade + ' ' + authorityName;
        } else if (authority.authority_id === 4) {
          const manageClass = staff.managed_class_info.school_class;
          const manageGrade = staff.managed_class_info.school_grade;
          displayString = manageGrade + ' ' + manageClass + ' ' + authorityName;
        } else {
          displayString = authorityName;
        }
      } else { // 存在しない権限の場合
        displayString = 'ーー';
      }
      return displayString;
    },
    logout() {
      localStorage.removeItem('loginInfo');
      this.$router.go(this.$router.currentRoute.path)
    }
  },
  watch: {
    group() {
      this.drawer = false
    },
    selectedYear(val) {
      this.setFiscalYear(val);
    }
  },
  async created() {
    await this.getCurrentSchool();
    if (this.loginUser.authority == 1) { 
      this.setFiscalYear(moment().subtract(3, 'months').year());
    } else {
      this.setFiscalYear(this.loginUser.fiscal_year);
    }
    this.selectedYear = this.fiscalYear;
  },
  mounted() {
    setInterval(() => {
      const loginInfoJson = localStorage.getItem('loginInfo');
      const loginInfo = JSON.parse(loginInfoJson);
      
      if (loginInfo && this.loginUser != null) {
        const storageStaffId = String(loginInfo.data.staff.staff_id)
        const storeStaffId = String(this.loginUser.staff_id)
        if (storageStaffId !== storeStaffId) {
          this.signOutDialog = true;
        }
      } else {
        this.signOutDialog = true;
      }
    }, 5000);
  }
}
</script>
<style scoped>
.fiscal_year {
  white-space: nowrap;
}

.fiscal_year_title {
  font-size: 15px;
}

.fiscal_year_selectbox {
  height: 28px;
  width: 153px;
}

.login-status {
  background-color: rgba(0, 0, 0, 0) !important;
  border: solid 1px;
  font-size: 12px;
  text-align: left;
}

.setting-btn-area {
  display: grid;
  background-color: transparent;
}

.setting-btn {
  color: green;
  border-top: 1px solid green;
}
</style>