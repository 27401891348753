<template>
  <div :class="{ 'contentsArea': !isSmartPhone }">
    <v-card>
      <v-btn text @click="showSearchBox = !showSearchBox">
        <v-icon left large color="primary">
          {{ showSearchBox ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
        欠席/遅刻連絡検索
      </v-btn>
      <v-expand-transition>
        <v-container v-if="showSearchBox" fluid>
          <v-form @submit.prevent ref="form">
            <v-row>
              <v-col :cols="isSmartPhone ? 3 : 2">
                <v-subheader :class="{ 'justify-end': !isSmartPhone, 'px-0': isSmartPhone }">
                  欠席日
                </v-subheader>
              </v-col>
              <v-col :cols="isSmartPhone ? 9 : 'auto'">
                <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selectedFromDate"
                      readonly
                      outlined
                      dense
                      prepend-inner-icon="mdi-calendar-outline"
                      placeholder="YYYY-MM-DD"
                      clearable
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selectedFromDate"
                    no-title
                    color="primary"
                    locale="jp-ja"
                    :day-format="(date) => new Date(date).getDate()"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="3" v-if="isSmartPhone">
              </v-col>
              <v-col :cols="isSmartPhone ? 9 : 'auto'" :class="{ 'mt-n11': isSmartPhone }">
                <v-subheader :class="{ 'justify-center': isSmartPhone }">
                  ~
                </v-subheader>
              </v-col>
              <v-col cols="3" v-if="isSmartPhone">
              </v-col>
              <v-col :cols="isSmartPhone ? 9 : 'auto'" :class="{ 'mt-n9': isSmartPhone }">
                <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selectedToDate"
                      readonly
                      outlined
                      dense
                      prepend-inner-icon="mdi-calendar-outline"
                      placeholder="YYYY-MM-DD"
                      clearable
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selectedToDate"
                    no-title color="primary"
                    locale="jp-ja"
                    :day-format="(date) => new Date(date).getDate()"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row :class="{ 'mb-n5': isSmartPhone }">
              <v-col :cols="isSmartPhone ? 3 : 2">
                <v-subheader :class="{ 'justify-end': !isSmartPhone, 'px-0': isSmartPhone }">種別</v-subheader>
              </v-col>
              <v-col :cols="isSmartPhone ? 9 : 3">
                <v-autocomplete
                  :items="items"
                  item-text="form_type"
                  item-value="form_type"
                  v-model="formTypeFilterInput"
                  flat
                  outlined
                  dense
                  clearable
                  hide-details
                  :inputmode="isSmartPhone ? 'none' : undefined"
                >
                  <template v-slot:selection="data">
                    <div>
                      {{ convertFormTypeToJapanese(data.item.form_type) }}
                    </div>
                  </template>
                  <template v-slot:item="data">
                    <div>
                      {{ convertFormTypeToJapanese(data.item.form_type) }}
                    </div>
                  </template>
                  <template v-slot:no-data>
                    <div class="px-4">データがありません</div>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="isSmartPhone ? 3 : 2" :class="{ 'mb-n5': isSmartPhone }">
                <v-subheader :class="{ 'justify-end': !isSmartPhone, 'px-0': isSmartPhone }">クラス</v-subheader>
              </v-col>
              <v-col :cols="isSmartPhone ? 9 : 3">
                <v-autocomplete 
                  :items="classroomAll"
                  :item-text="item => item.grade_name + ' ' + item.class_name"
                  item-value="classroom_id"
                  v-model="classroomFilterInput"
                  @change="studentFilterInput = ''"
                  flat
                  outlined
                  dense
                  clearable
                  hide-details
                  :inputmode="isSmartPhone ? 'none' : undefined"
                >
                  <template v-slot:no-data>
                    <div class="px-4">データがありません</div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col :cols="isSmartPhone ? 3 : 2">
                <v-subheader  :class="{ 'justify-end': !isSmartPhone, 'px-0': isSmartPhone }">生徒氏名</v-subheader>
              </v-col>
              <v-col :cols="isSmartPhone ? 9 : 'auto'">
                <v-autocomplete 
                  :disabled="classroomFilterInput == null || classroomFilterInput == '' || classroomFilterInput == undefined "
                  :items="items.filter(item => classroomFilter(item.classroom_id)).sort((a,b) => a.number - b.number)"
                  :item-text="item => (item.number + '番 : ' + item.last_name + ' ' + item.first_name)"
                  item-value="student_id"
                  v-model="studentFilterInput"
                  flat
                  outlined
                  dense
                  clearable
                  hide-details
                  :inputmode="isSmartPhone ? 'none' : undefined"
                >
                  <template v-slot:no-data>
                    <div class="px-4">データがありません</div>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-expand-transition>
      <v-divider></v-divider>
      <v-container class="my-4" fluid>
        <v-row no-gutters>
          <v-col cols="auto">
            <span class="display-1">{{ filteredItems.length }}</span>
            <span>件</span>
          </v-col>
          <v-divider vertical class="mx-4"></v-divider>
          <v-btn :loading="loading" color="primary" class="mr-3" @click="reload" :x-small="isSmartPhone" :style="{'height': isSmartPhone ? '36px' : auto}">
            <v-icon :left="!isSmartPhone">mdi-reload</v-icon>
            <span v-show="!isSmartPhone">データ更新</span>
          </v-btn>
          <v-btn :loading="loading" outlined color="primary" class="mr-3" @click="openDialogCreateForm()" :x-small="isSmartPhone" :style="{'height': isSmartPhone ? '36px' : auto}">
            <v-icon :left="!isSmartPhone" style="padding-top: 5px; padding-bottom: 5px">mdi-file-document-plus-outline</v-icon>
            <span v-show="!isSmartPhone">新規作成</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="items.length < 1" :loading="loading" outlined color="primary" class="mr-3" @click="downloadCsv" :x-small="isSmartPhone" :style="{'height': isSmartPhone ? '36px' : auto}">
            <v-icon :left="!isSmartPhone">mdi-file-export-outline</v-icon>
            <span v-show="!isSmartPhone">CSVエクスポート</span>
          </v-btn>
          <v-btn :loading="loading" @click="clearSearchItems" color="gray" :x-small="isSmartPhone" :style="{'height': isSmartPhone ? '36px' : auto}">
            <v-icon :left="!isSmartPhone">mdi-filter-variant-remove</v-icon>
            <span v-show="!isSmartPhone">条件をクリアする</span>
          </v-btn>
        </v-row>
      </v-container>
      <Pagination
        :totalCount="filteredItems.length"
        :page="currentPage"
        :perOnPage="perOnPage"
        :perOnPageOptions="perOnPageOptions"
        :loading="loading"
        @changePage="changePage"
        @changePerOnPage="changePerOnPage"
      />
      <div :class="{'smartphone-data-table': isSmartPhone}">
        <v-data-table
          :items="items"
          :headers="headers"
          :loading="loading"
          :loading-text="loadingText"
          :no-data-text="noDataText"
          :no-results-text="noResultText"
          :items-per-page="perOnPage"
          :page.sync="currentPage"
          single-select
          hide-default-footer
          class="table-cursor"
        >
          <template v-slot:[`item.form_type`]="{ item }">
            <div class="form-type" :class="['form-type-' + item.form_type, { 'py-0 px-2': isSmartPhone }]">
              {{ convertFormTypeToJapanese(item.form_type) }}
            </div>
          </template>
          <template v-slot:[`item.absence_at`]="{ item }">
            <div v-if="item.form_type === 'absence'">
              {{ convertTimeToMMDD(item.absence_at) }}
            </div>
            <div v-else-if="item.form_type === 'late'">
              {{ convertTimeToMMDD(item.absence_at) }}
              <br>{{ convertArriveAtToJapanese(item.arrive_at) + "ごろ迄" }}
            </div>
            <div v-else-if="item.form_type === 'early'">
              {{ convertTimeToMMDD(item.absence_at) }}
              <br>{{ convertArriveAtToJapanese(item.arrive_at) + "ごろ早退" }}
            </div>
          </template>
          <template v-slot:[`item.classroom_id`]="{ item }">
            <div>
              {{ item.grade_name + ' ' + item.class_name }}
            </div>
          </template>
          <template v-slot:[`item.student_id`]="{ item }">
            <div>
              {{ item.last_name + ' ' + item.first_name }}
            </div>
          </template>
          <template v-slot:[`item.student_name_kana`]="{ item }">
            <div>
              {{ item.last_name_kana + ' ' + item.first_name_kana }}
            </div>
          </template>
          <template v-slot:[`item.absence_reason_id`]="{ item }">
            <div>
              {{ convertAbsenceReasonIdToJapanese(item.absence_reason_id) }}
            </div>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <div>
              {{ item.created_at }}
            </div>
          </template>
        </v-data-table>
      </div>
      <Pagination
        :totalCount="filteredItems.length"
        :page="currentPage"
        :perOnPage="perOnPage"
        :perOnPageOptions="perOnPageOptions"
        :loading="loading"
        @changePage="changePage"
        @changePerOnPage="changePerOnPage"
      />
    </v-card>
    <v-dialog v-model="loading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          読み込んでいます
          <v-progress-linear indeterminate color="white" class="mb-0" ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <AbsenceFormCreator v-model="dialogCreateForm" v-if="dialogCreateForm"/>
  </div>
</template>

<script>
import AbsenceFormCreator from './fragments/AbsenceFormCreator.vue';
import Pagination from "./fragments/Pagination.vue";
import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { saveAs } from "file-saver";
import {
  GET_ABSENCE_FORM_ALL,
  GET_ABSENCE_FORM_ALL_LENGTH,
  GET_CLASSROOM_ALL,
} from "@/store/action-types";
import {
} from "@/store/mutation-types";
import {
  LOADING_TEXT,
  NO_DATA_TEXT,
  NO_RESULT_TEXT,
  ABSENCE_FORM_TYPE_LIST,
  ABSENCE_REASON_LIST,
} from "@/constants";
import moment from 'moment';
import Encoding from 'encoding-japanese';

export default Vue.extend({
  name: 'AbsenceForm',
  components: {
    AbsenceFormCreator,
    Pagination,
  },
  data() {
    return {
      // テーブル表示
      items: [],
      currentPage: 1,
      perOnPage: 10,
      perOnPageOptions: [
        10,
        20,
        50,
        100,
        -1
      ],
      loading: false,
      noDataText: NO_DATA_TEXT,
      noResultText: NO_RESULT_TEXT,
      loadingText: LOADING_TEXT,
      absenceFormTypeList: ABSENCE_FORM_TYPE_LIST,
      absenceReasonList: ABSENCE_REASON_LIST,

      // 検索
      showSearchBox: true,
      selectedFromDate: null,
      selectedToDate: null,
      formTypeFilterInput: '',
      classroomFilterInput: '',
      studentFilterInput: '',

      // 新規作成ダイアログ
      dialogCreateForm: false,

      // 自動更新
      reloadable: true,

      // CSVエクスポート
      csvHeaders: ["種別", "欠席日", "登校時間", "学年", "クラス", "生徒氏名", "生徒氏名(カナ)", "欠席理由", "備考", "受付日時"],
    };
  },
  mounted() {
    this.autoReload();
  },
  computed: {
    ...mapState({
      absenceFormAll: (state) => state.absenceFormAll,
      absenceFormAllLength: (state) => state.absenceFormAllLength,
      classroomAll: state => state.classroomAll,
      fiscalYear: (state) => state.fiscalYear,
      isSmartPhone: (state) => state.isSmartPhone,
    }),
    headers() {
      return [
        { text: "種別", value: "form_type", filter: this.formTypeFilter, width: "8%" },
        { text: "欠席日", value: "absence_at", filter: this.absenceAtFilter, width: "12%" },
        { text: "クラス", value: "classroom_id", filter: this.classroomFilter, width: "8%" },
        { text: "生徒氏名", value: "student_id", filter: this.studentFilter, width: "12%" },
        { text: "生徒氏名(カナ)", value: "student_name_kana", width: "12%" },
        { text: "欠席理由", value: "absence_reason_id", width: "15%" },
        { text: "備考", value: "remarks", width: "20%" },
        { text: "受付日時", value: "created_at", width: "13%" }
      ];
    },
    pages() {
      if (this.items.length == null) {
        return 0;
      }
      return Math.ceil(this.items.length / this.perOnPage);
    },
    paginatedItems() {
      const items = (this.perOnPage === -1) ? this.items : this.paginate(this.items);
      return items;
    },
    filteredItems() {
      return this.items.filter(item => {
        return(
          this.formTypeFilter(item.form_type) &&
          this.absenceAtFilter(item.absence_at) &&
          this.classroomFilter(item.classroom_id) &&
          this.studentFilter(item.student_id)
        );
      });
    },
  },
  methods: {
    ...mapActions({
      getAbsenceFormAll: GET_ABSENCE_FORM_ALL,
      getAbsenceFormAllLength: GET_ABSENCE_FORM_ALL_LENGTH,
      getClassroomAll: GET_CLASSROOM_ALL,
    }),
    ...mapMutations({
    }),
    convertFormTypeToJapanese(formType) {
      return this.absenceFormTypeList.find(f => f.id === formType)?.text;
    },
    convertAbsenceReasonIdToJapanese(absenceReasonId) {
      return this.absenceReasonList.find(r => r.id === absenceReasonId)?.text;
    },
    convertArriveAtToJapanese(arrive_at) {
      if (arrive_at === null || arrive_at === "" || arrive_at === undefined) {
        return "";
      }
      const timeArray = arrive_at.split(":");
      const hour = parseInt(timeArray[0]);
      const minute = parseInt(timeArray[1]);
      return hour + "時 " + minute + "分";
    },
    convertTimeToMMDD(time) {
      return moment(time).format('M月 D日');
    },
    formTypeFilter(value) {
      if (!this.formTypeFilterInput) {
        return true;
      }
      return value.toLowerCase().includes(this.formTypeFilterInput.toLowerCase());
    },
    absenceAtFilter(value) {
      if (!this.selectedFromDate || !this.selectedToDate) {
        return true;
      }
      return moment(value).isBetween(this.selectedFromDate, this.selectedToDate, undefined, '[]');
    },
    classroomFilter(value) {
      if (!this.classroomFilterInput) {
        return true;
      }
      return (value === this.classroomFilterInput);
    },
    studentFilter(value) {
      if (!this.studentFilterInput) {
        return true;
      }
      return (value === this.studentFilterInput);
    },
    clearSearchItems() {
      this.selectedFromDate = null;
      this.selectedToDate = null;
      this.formTypeFilterInput = null;
      this.classroomFilterInput = null;
      this.studentFilterInput = null;
    },
    async reload() {
      this.loading = true;
      await this.getAbsenceFormAll(); // 欠席連絡全件取得
      this.items = [...this.absenceFormAll];
      this.loading = false;
    },
    openDialogCreateForm() {
      this.dialogCreateForm = true;
    },
    downloadCsv() {
      const data = this.items.map((item) => {
        const form_type = this.convertFormTypeToJapanese(item.form_type);
        const absence_at = this.convertTimeToMMDD(item.absence_at);
        const arrive_at = this.convertArriveAtToJapanese(item.arrive_at);
        const grade_name = item.grade_name;
        const class_name = item.class_name;
        const name = item.last_name + ' ' + item.first_name;
        const name_kana = item.last_name_kana + ' ' + item.first_name_kana;
        const absence_reason = this.convertAbsenceReasonIdToJapanese(item.absence_reason_id);
        const remarks = item.remarks.replace(/\r?\n/g, '');
        const created_at = item.created_at;
        return [form_type, absence_at, arrive_at, grade_name, class_name, name, name_kana, absence_reason, remarks, created_at];
      });
      const csvRows = [];
      const keys = Object.keys(data[0]);

      // ヘッダー行をCSVに追加
      csvRows.push(this.csvHeaders.join(','));

      // データ行をCSVに追加
      for (const row of data) {
        const values = keys.map(key => {
          return row[key];
        });
        // カンマ区切りの文字列にする
        let csvRow = values.join(',');
        // CSVデータに追加
        csvRows.push(csvRow);
      }
      // CSVをShift-JISにエンコード
      const csvString = csvRows.join('\r\n');
      const unicodeList = Encoding.stringToCode(csvString);
      const shiftJisCodeList = Encoding.convert(unicodeList, 'SJIS', 'UNICODE');
      const shiftJisString = new Uint8Array(shiftJisCodeList);
      // CSVファイルをダウンロード
      const fileName = 'Absence.csv';
      const blob = new Blob([shiftJisString], { type: 'text/csv;charset=sjis' });
      saveAs(blob, fileName);
    },
    async setAbsenceFormList() {
      await this.getAbsenceFormAllLength(); // 欠席連絡総件数を取得する

      // storeの欠席連絡の件数が0件 または 欠席連絡総件数と差がある場合全件取得
      if ((this.absenceFormAll.length === 0) || (this.absenceFormAllLength !== this.absenceFormAll.length)){
        await this.getAbsenceFormAll(); // 欠席連絡全件取得
      }
      this.items = [...this.absenceFormAll];
    },
    async setClassroomList() {
      await this.getClassroomAll();
    },
    async autoReload() {
      while(this.reloadable) {
        await this.wait(60000); // 1分(60000ミリ秒)ごと
        this.setAbsenceFormList();
      }
    },
    wait(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    // Pagination処理
    paginate(items) {
      const start = (this.currentPage - 1) * this.perOnPage;
      return items.slice(start, start + this.perOnPage);
    },
    changePage(currentPage) {
      this.currentPage = currentPage;
    },
    changePerOnPage(perOnPage) {
      this.perOnPage = perOnPage;
    },
  },
  watch: {
    dialogCreateForm(newVal) {
      if (newVal === false) {
        this.reload();
      }
    },
    fiscalYear() {
      this.reload();
    }
  },
  async created() {
    this.loading = true;
    await this.setAbsenceFormList();
    await this.setClassroomList();
    this.loading = false;
  },
});
</script>

<style scoped>
.form-type {
  border-radius: 16px;
  padding: 6px 10px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}
.form-type-absence {
  background-color: rgba(210, 30, 45, 1);
}
.form-type-late {
  background-color: rgba(210, 105, 30, 1);
}
.form-type-early {
  background-color: rgba(30, 105, 210, 1);
}
.smartphone-data-table ::v-deep .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row{
  height: unset;
  min-height: 30px;
}
</style>